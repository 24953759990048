import { useCallback } from 'react';

import { useGetAllDevicesQuery, useGetDevicesQuery } from '@services/devices/endpoints';
import { useGetSiteDevicesQuery } from '@services/sites/endpoints';

import { DevicesAndGroupsCommonProps } from '../devicesAndGroupsTypes';
import { useChargersWithStats } from './useChargersWithStats';
import { useInvertersWithStats } from './useInvertersWithStats';
import { useLmcMultipointsWithStats } from './useLmcMultipointsWithStats';

export const useDevicesAndGroups = (props: DevicesAndGroupsCommonProps) => {
  const { siteUuid, companyUuid } = props;

  const { refetch: refetchSiteDevices, isLoading: isLoadingGetSiteDevices } = useGetSiteDevicesQuery(siteUuid!, {
    refetchOnMountOrArgChange: true,
    skip: !siteUuid,
  });

  const { refetch: refetchCompanyDevices, isLoading: isLoadingGetCompanyDevices } = useGetDevicesQuery(
    { companyUuid },
    {
      refetchOnMountOrArgChange: true,
      skip: !companyUuid,
    },
  );

  const { refetch: refetchAllDevices, isLoading: isLoadingAllDevices } = useGetAllDevicesQuery(
    { forBackoffice: true },
    {
      refetchOnMountOrArgChange: true,
      skip: !!siteUuid || !!companyUuid,
    },
  );

  const refetchDevices = useCallback(() => {
    if (siteUuid) {
      refetchSiteDevices();
    }
    if (companyUuid) {
      refetchCompanyDevices();
    }
    if (!siteUuid && !companyUuid) {
      refetchAllDevices();
    }
  }, [siteUuid, companyUuid]);

  const {
    lmcMultipointsWithStats,
    selectedLmcMultipoint,
    isLoading: isLoadingLmcMultipoints,
  } = useLmcMultipointsWithStats(props);

  const { chargersWithStats, selectedCharger } = useChargersWithStats({
    ...props,
    lmcMultipointsWithStats,
  });

  const {
    invertersWithStats,
    isLoading: isInvertersLoading,
    isFetchingGetSiteInvertersSummary,
    refetchInverters,
  } = useInvertersWithStats(props);

  const dataLoading =
    isLoadingGetSiteDevices ||
    isLoadingGetCompanyDevices ||
    isLoadingLmcMultipoints ||
    isInvertersLoading ||
    isLoadingAllDevices;

  return {
    lmcMultipointsWithStats,
    chargersWithStats,
    invertersWithStats,
    selectedLmcMultipoint,
    selectedCharger,
    dataLoading,
    isFetchingGetSiteInvertersSummary,
    refetchDevices,
    refetchInverters,
  };
};
