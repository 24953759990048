import { useTranslation } from 'react-i18next';

import { FeatureDto, FeatureId } from 'models/subscriptions';

import { Box, useTheme } from '@mui/material';

import { StyledButton } from '@components/atoms/StyledButton';
import { ReactComponent as ArrowIcon } from '@images/icons/arrow_left.svg';
import { ReactComponent as EllipseIcon } from '@images/icons/subscriptions/ellipse.svg';

import { useSubscriptionItemCount } from './useSubscriptionItemCount';

type FeatureDetailsButtonProps = {
  feature: FeatureDto;
};

export const FeatureDetailsButton = ({ feature }: FeatureDetailsButtonProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { count } = useSubscriptionItemCount(feature);

  const featureItemSingularMap: Record<FeatureId, string> = {
    'device.public-charging': t('device', 'Device'),
    'company.report-schedules': t('schedule', 'Schedule'),
    'device.third-party-chargers': t('charger', 'Charger'),
  };

  const featureItemPluralMap: Record<FeatureId, string> = {
    'device.public-charging': t('devices', 'Devices'),
    'company.report-schedules': t('schedules', 'Schedules'),
    'device.third-party-chargers': t('chargers', 'Chargers'),
  };

  if (!count) {
    return null;
  }

  return (
    <Box>
      <StyledButton color="status">
        <Box display="flex" alignItems="center" gap={1}>
          <EllipseIcon width={8} height={8} color={theme.palette.forestGreenCrayola} />
          {t('countActiveItems', '{{count}} active {{item}}', {
            count,
            item: (count > 0
              ? featureItemPluralMap[feature.featureId]
              : featureItemSingularMap[feature.featureId]
            ).toLowerCase(),
          })}
          <ArrowIcon style={{ transform: 'rotate(180deg)' }} />
        </Box>
      </StyledButton>
    </Box>
  );
};
