import { ReactNode } from 'react';

import { Box, BoxProps, Typography, useTheme } from '@mui/material';

type BulletListProps = BoxProps & {
  children: ReactNode | ReactNode[];
};

export const BulletList = ({ children, ...props }: BulletListProps) => {
  const theme = useTheme();
  const childrenArray = Array.isArray(children) ? children : [children];

  return (
    <Box display="flex" flexDirection="column" color={theme.palette.darkGray} {...props}>
      {childrenArray.map((child) => (
        <Box key={crypto.randomUUID()} display="flex">
          <Typography variant="p14">&nbsp;&nbsp;•&nbsp;&nbsp;</Typography>
          {child}
        </Box>
      ))}
    </Box>
  );
};
