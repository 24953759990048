import { Box, useMediaQuery, useTheme } from '@mui/material';

import { PartnersDevicesDashboard } from './PartnersDevicesDashboard';
import { PartnersSummary } from './PartnersSummary';
import { PartnersWorkspacesDashboard } from './PartnersWorkspacesDashboard';

export const PartnersDashboard = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box display="flex" flexDirection="column" gap={2} width={1}>
      <PartnersSummary />
      <Box display="flex" flexDirection={isSmallScreen ? 'column' : 'row'} gap={2} alignItems="stretch">
        <PartnersDevicesDashboard />
        <PartnersWorkspacesDashboard />
      </Box>
    </Box>
  );
};
