import {
  ChargingAction,
  ConnectorComputedStatus,
  ConnectorFormat,
  ConnectorMeterValueKey,
  ConnectorType,
  DeviceConnectorChargingProgramAlgorithm,
  DeviceFirmwareUpdateStatus,
  DeviceStatus,
  DeviceTransactionStatus,
  DeviceType,
  GetOcppVariableStatus,
  LmcConnectionType,
  OcppStatus,
  OcppVersion,
  PowerType,
} from './device.enums';

export interface ConnectorConfig {
  connectorId: number;
  powerType: PowerType;
  connectorType: ConnectorType;
  connectorFormat: ConnectorFormat;
  maxPowerKw: number;
}
export interface ConnectorState extends Partial<Record<ConnectorMeterValueKey, number>> {
  connectorId: number;
  ocppStatus?: OcppStatus;
  transactionStatus?: DeviceTransactionStatus;
  computedStatus?: ConnectorComputedStatus;
  reserved: boolean;
  lastCarUuid?: string;
  localFailedAction?: ChargingAction;
  localFailedActionTime?: string;
}
export interface DeviceConfigurationItem {
  value: any;
  readonly: boolean;
}
export interface DeviceConnectorActiveProgram {
  connectorId: number;
  algorithm: DeviceConnectorChargingProgramAlgorithm;
  configuration?: Record<string, unknown>;
  context?: {
    carUuid: string;
  };
}

export interface LmcChargerCommunicationStatus {
  lmcUuid: string;
  deviceSerialNumber: string;
  isCommunicationOk: boolean;
  interface?: LmcConnectionType;
}

export interface Device {
  activePrograms?: DeviceConnectorActiveProgram[];
  activePower?: number;
  uuid: string;
  userUuid: string;
  companyUuid?: string;
  siteUuid?: string;
  name: string;
  status: DeviceStatus;
  ocppVersion?: OcppVersion;
  ocppStatus?: OcppStatus;
  vendor?: string;
  model?: string;
  firmwareVersion?: string;
  firmwareUpdateStatus?: DeviceFirmwareUpdateStatus;
  firmwareUpdateStatusTime?: string;
  type: DeviceType;
  serialNumber: string;
  deviceSerialNumber?: string;
  pinCode?: string;
  deleted?: boolean;
  addTime: Date;
  updateTime: Date;
  gridConnection?: any;
  transactionStatus?: DeviceTransactionStatus;
  ocppPassword?: string;
  maxPowerKw?: number;
  configOfConnectors?: ConnectorConfig[];
  stateOfConnectors?: ConnectorState[];
  configuration?: Record<string, DeviceConfigurationItem>;
  lmcMultipointUuid?: string;
  voolDeviceFlag?: boolean;
  communicatesViaLmc?: boolean;
  publicFlag: boolean;
  active_power?: number;
  inverterId?: string;
  metricsPusherChannel?: string;
}

export interface GetDeviceVariableResponseItem {
  component?: string;
  variable: string;
  readonly?: boolean;
  attributeStatus: GetOcppVariableStatus;
  attributeValue?: string;
}

export enum SetVariableAttributeStatus {
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected',
  INVALID_VALUE = 'InvalidValue',
  UNKNOWN_COMPONENT = 'UnknownComponent',
  UNKNOWN_VARIABLE = 'UnknownVariable',
  NOT_SUPPORTED_ATTRIBUTE_TYPE = 'NotSupportedAttributeType',
  OUT_OF_RANGE = 'OutOfRange',
  REBOOT_REQUIRED = 'RebootRequired',
  // custom status
  FAILED = 'Failed',
}

export interface SetDeviceVariableResponseItem {
  attributeStatus: SetVariableAttributeStatus;
  component?: string;
  variable: string;
}
