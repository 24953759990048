import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';

import { Permission } from 'models/permission.enum';
import { Site } from 'models/site';

import Button from '@components/atoms/Button';
import NoItemsCard from '@components/cards/NoItemsCard';
import { selectPermissionByName } from '@services/auth/selectors';
import { useGetDevicesQuery } from '@services/devices/endpoints';
import { useAppSelector } from '@services/hooks';
import { useGetSitesQuery } from '@services/sites/endpoints';
import { selectActiveCompanySites, selectSiteByUuid } from '@services/sites/selectors';

import { DeleteSiteModal } from '../DeleteSiteModal';
import { SitesMaterialTable } from './SitesMaterialTable';

const SitesTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const siteUuid = searchParams.get('siteUuid') ?? '';

  const sites = useAppSelector(selectActiveCompanySites);
  const selectedSiteFromUrl = useAppSelector((state) => selectSiteByUuid(state, siteUuid));
  const canAddSites = useAppSelector((state) => selectPermissionByName(state, Permission.CAN_ADD_SITES));

  const [siteToDelete, setSiteToDelete] = useState<Site>();

  const { isLoading: isLoadingGetSites } = useGetSitesQuery({});
  const { isLoading: isLoadingGetDevices } = useGetDevicesQuery({});

  return (
    <>
      {!!sites.length && canAddSites && (
        <Button variant="primary" className="float-right -mt-0.5" onClick={() => navigate('/sites/new')}>
          {t('addSite', 'Add a site')}
        </Button>
      )}
      <h2 className="mb-4 mt-8 font-poppins text-lg font-semibold leading-8">{t('yourSites', 'Your sites')}</h2>
      {!sites.length && !isLoadingGetSites ? (
        <NoItemsCard
          message={t('noSitesText', 'There are currently no sites.')}
          {...(canAddSites && {
            buttonLabel: t('addSite', 'Add a site'),
            onButtonClick: () => navigate('/sites/new'),
          })}
        />
      ) : (
        <SitesMaterialTable
          sites={sites}
          selectedSite={selectedSiteFromUrl}
          isLoading={isLoadingGetSites || isLoadingGetDevices}
          onOpenDeleteSiteModal={setSiteToDelete}
        />
      )}
      {siteToDelete && (
        <DeleteSiteModal site={siteToDelete} isOpen={!!siteToDelete} closeModal={() => setSiteToDelete(undefined)} />
      )}
      <Outlet context={{ site: selectedSiteFromUrl }} />
    </>
  );
};

export default SitesTable;
