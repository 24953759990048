import { Trans } from 'react-i18next';

import { Box, Typography, useTheme } from '@mui/material';

import { StyledCheckbox } from '@components/atoms/StyledCheckbox';

type SubscriptionTermsOfUseBoxProps = {
  termsAccepted: boolean;
  onTermsAcceptedChange: (isAccepted: boolean) => void;
};

export const SubscriptionTermsOfUseBox = ({ termsAccepted, onTermsAcceptedChange }: SubscriptionTermsOfUseBoxProps) => {
  const theme = useTheme();

  // TODO: Load Terms of Use from the backend once it's finalized

  return (
    <Box
      display="flex"
      alignItems="center"
      gap={1}
      p={2}
      color={theme.palette.darkGray}
      bgcolor={theme.palette.cultured}
      borderRadius={2}
    >
      <StyledCheckbox checked={termsAccepted} onChange={(e, checked) => onTermsAcceptedChange(checked)} />
      <Typography variant="p14">
        <Trans
          i18nKey="byPlacingThisOrderIAgreeToTheTermsOfUseAndPrivacyNotice"
          defaults="By placing this order, I agree to the <1>Terms of Use</1> and <1><2>Privacy Notice</2></1>."
          components={{
            1: (
              <Typography
                variant="p14"
                color={theme.palette.mediumVermilion}
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
              />
            ),
            2: (
              <a
                href="https://www.vool.com/privacy-policy/"
                target="_blank"
                rel="noreferrer"
                aria-label="Link to VOOL Privacy Policy"
              />
            ),
          }}
        />
      </Typography>
    </Box>
  );
};
