import { useTranslation } from 'react-i18next';

import { Box, Typography, useTheme } from '@mui/material';

import { BulletList } from '@components/atoms/BulletList';

export const PublicChargingSubscriptionDescription = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="column" color={theme.palette.darkGray}>
      <Typography variant="p14b">
        {t('publicCharging.makeYourChargerPublic', 'Make your EV charger public and earn extra revenue')}
      </Typography>
      <BulletList>
        <Typography variant="p14">
          {t('publicCharging.chooseElectricityContract', 'Choose your electricity contract type: fixed or variable.')}
        </Typography>
        <Typography variant="p14">{t('publicCharging.setProfitMargin', 'Set your desired profit margin.')}</Typography>
        <Typography variant="p14">
          {t(
            'publicCharging.chargerVisibility',
            'Make your charger public: your charger will be visible on Google Maps, Waze, and other major navigation platforms.',
          )}
        </Typography>
        <Typography variant="p14">
          {t(
            'publicCharging.paymentProcessing',
            'Seamless payment processing: we handle payments on your behalf, accepting Apple Pay, Google Pay, and credit cards from end customers.',
          )}
        </Typography>
        <Typography variant="p14">
          {t(
            'publicCharging.reservationAndPaidParking',
            'Enable charger reservation and paid parking: optional features for added convenience.',
          )}
        </Typography>
      </BulletList>
    </Box>
  );
};
