import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Input } from '@components/atoms/Input';
import SaveModal from '@components/modals/SaveModal';
import { useCreateCompanyMutation } from '@services/companies/endpoints';
import { useAppDispatch } from '@services/hooks';
import { addToastMessage } from '@services/toastMessages';

type CreateWorkspaceModalProps = {
  isOpen: boolean;
  closeModal: () => void;
};

export const CreateWorkspaceModal = ({ isOpen, closeModal }: CreateWorkspaceModalProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [name, setName] = useState('');

  const [createWorkspace, { isLoading }] = useCreateCompanyMutation();

  const handleSave = async () => {
    try {
      await createWorkspace({ name: name.trim() }).unwrap();
      dispatch(addToastMessage({ type: 'success', title: t('workspaceCreated', 'Workspace created') }));
    } catch (error) {
      dispatch(
        addToastMessage({
          type: 'error',
          title: t('creatingTheWorkspaceFailed', 'Creating the workspace failed'),
          message: t('pleaseTryAgain', 'Please try again.'),
        }),
      );
    }
    closeModal();
  };

  const canSave = !isLoading && !!name.trim();

  useEffect(() => {
    if (isOpen) {
      setName('');
    }
  }, [isOpen]);

  return (
    <SaveModal
      title={t('createNewWorkspace', 'Create new workspace')}
      content={() => ''}
      saveModalContent={handleSave}
      buttonText={t('createWorkspace', 'Create workspace')}
      saveButtonDisabled={!canSave}
      closeModal={closeModal}
      open={isOpen}
    >
      <Input
        name="newName"
        label={t('workspaceName', 'Workspace name')}
        value={name}
        onChange={(e) => setName(e.target.value)}
        inputProps={{ onKeyDown: (e) => e.key === 'Enter' && canSave && handleSave() }}
      />
    </SaveModal>
  );
};
