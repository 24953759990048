import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { get } from 'lodash';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import Button from '@components/atoms/Button';
import { Card } from '@components/atoms/Card';
import { Checkbox } from '@components/atoms/Checkbox';
import { Input } from '@components/atoms/Input';
import { OverflowSpinner } from '@components/spinner/OverflowSpinner';
import { useForm } from '@hooks';
import googleIcon from '@images/icons/google.svg';
import apiSlice from '@services/api';
import { useUpdateUserPasswordMutation } from '@services/users/endpoints';

const useSchema = () => {
  const { t } = useTranslation();
  return yup
    .object()
    .shape({
      name: yup.string().required(t('required*', 'Required*')),
      emailNotificationsFlag: yup.bool(),
      currentPassword: yup.string(),
      newPassword: yup.string().test('required', t('required*', 'Required*'), (value, context) => {
        const { currentPassword } = context.parent;
        return !currentPassword || !!value;
      }),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('newPassword'), null], t('passwordsMustMatch', 'Passwords must match')),
    })
    .required();
};

const UserSettingsForm = ({ self, className, closeModal }) => {
  const { t } = useTranslation();
  const [nameUpdateError, setNameUpdateError] = useState(null);
  const [passwordUpdateError, setPasswordUpdateError] = useState(null);

  const schema = useSchema();
  const { register, handleSubmit, formState, setValue } = useForm({ schema });

  const [updateUser, { isLoading: userUpdateLoading }] = apiSlice.useUpdateUserMutation();
  const [updatePassword, { isLoading: passwordUpdateLoading }] = useUpdateUserPasswordMutation();

  const loading = userUpdateLoading || passwordUpdateLoading;

  const handleCloseModal = () => {
    setValue('currentPassword', '');
    setValue('newPassword', '');
    setValue('confirmPassword', '');
    setNameUpdateError(null);
    setPasswordUpdateError(null);
    closeModal();
  };

  const onSubmit = async ({ name, emailNotificationsFlag, currentPassword, newPassword }) => {
    try {
      const [updateUserResponse, updatePasswordResponse] = await Promise.all([
        updateUser({
          name,
          emailNotificationsFlag: emailNotificationsFlag ? 1 : 0,
        }),
        currentPassword &&
          newPassword &&
          updatePassword({
            currentPassword,
            newPassword,
          }),
      ]);

      const errorMessage = get(updateUserResponse.error || updatePasswordResponse?.error, 'data.message', null);
      if (errorMessage) {
        throw new Error(errorMessage);
      }

      handleCloseModal();
    } catch (error) {
      const { message: errorMessage } = error;
      if (errorMessage.includes('password')) {
        setPasswordUpdateError(t('currentPasswordIncorrect', 'Current password is incorrect'));
      } else {
        setNameUpdateError(errorMessage);
      }
    }
  };

  useEffect(() => {
    setValue('name', self.name);
    setValue('emailNotificationsFlag', Boolean(self.emailNotificationsFlag));
  }, [self.name, self.emailNotificationsFlag]);

  const registerWithOnChange = (field, onChange) => {
    const origRegister = register(field);
    return {
      ...origRegister,
      onChange: (e) => {
        origRegister.onChange(e);
        onChange(e);
      },
    };
  };

  return (
    <div className={`relative ${className}`}>
      <Card full>
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-4 space-y-3">
            <div className="flex w-full items-center gap-x-2 font-poppins text-lg font-semibold">
              {!!self.googleVerified && <img src={googleIcon} alt="Google icon" className="h-6 w-6" />}
              {self.email}
            </div>
            <Input
              name="name"
              label={t('name', 'Name')}
              type="text"
              error={Boolean(formState.errors?.name) || Boolean(nameUpdateError)}
              helpText={formState.errors?.name?.message || nameUpdateError}
              {...registerWithOnChange('name', () => setNameUpdateError(null))}
            />
          </div>
          <div className="mt-10 space-y-4">
            <div className="w-full font-poppins text-lg font-semibold">{t('notifications', 'Notifications')}</div>
            <Checkbox
              label={t('receiveNotificationEmails', 'Receive notification emails')}
              name="emailNotificationsFlag"
              {...register('emailNotificationsFlag')}
            />
            <div className="text-xs text-gray-600">
              {t(
                'notificationEmailsDisabledNotice',
                'By disabling notification emails, you may miss warnings or alerts for important issues at your site(s).',
              )}
            </div>
          </div>
          {!!self.hasPassword && (
            <div className="mt-10 space-y-4">
              <div className="w-full font-poppins text-lg font-semibold">{t('changePassword', 'Change password')}</div>
              <Input
                name="currentPassword"
                label={t('currentPassword', 'Current password')}
                type="password"
                error={Boolean(formState.errors?.currentPassword) || Boolean(passwordUpdateError)}
                helpText={formState.errors?.currentPassword?.message || passwordUpdateError}
                {...registerWithOnChange('currentPassword', () => setPasswordUpdateError(null))}
              />
              <Input
                name="newPassword"
                label={t('newPassword', 'New password')}
                type="password"
                autoComplete="new-password"
                error={Boolean(formState.errors?.newPassword)}
                helpText={formState.errors?.newPassword?.message}
                {...register('newPassword')}
              />
              <Input
                name="confirmPassword"
                label={t('confirmPassword', 'Confirm password')}
                type="password"
                autoComplete="new-password"
                error={Boolean(formState.errors?.confirmPassword)}
                helpText={formState.errors?.confirmPassword?.message}
                {...register('confirmPassword')}
              />
            </div>
          )}

          <div className="mt-6 w-full rounded-b-2xl border-t border-gray-100 pt-4 sm:flex sm:justify-between sm:gap-2">
            <Button variant="secondary" className="w-full" onClick={handleCloseModal}>
              {t('cancel', 'Cancel')}
            </Button>
            <Button type="submit" variant="primary" className="mt-3 w-full sm:mt-0">
              {t('saveChanges', 'Save changes')}
            </Button>
          </div>
        </form>
      </Card>
      {loading && <OverflowSpinner size="8" className="m-1 rounded-2xl" />}
    </div>
  );
};

UserSettingsForm.propTypes = {
  self: PropTypes.object.isRequired,
  className: PropTypes.string,
  closeModal: PropTypes.func,
};

UserSettingsForm.defaultProps = {
  className: '',
  closeModal: () => {},
};

export default UserSettingsForm;
