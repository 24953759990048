import { sumBy } from 'lodash';
import moment from 'moment';

export type DurationKey = '24-hours' | '1-week' | '1-months' | '6-months' | '1-year' | 'all';

export const durationToTimeFrame: Record<DurationKey, keyof typeof chartDataParsers> = {
  '24-hours': 'today',
  '1-week': 'week',
  '1-months': 'month',
  '6-months': 'sixMonths',
  '1-year': 'year',
  all: 'year',
};

export const getFormattedStartTimeForDuration = (duration: DurationKey | undefined) => {
  if (!duration || duration === 'all') {
    return undefined;
  }
  const durationToStartTime: Record<Exclude<DurationKey, 'all'>, moment.Moment> = {
    '24-hours': moment().subtract(1, 'days'),
    '1-week': moment().subtract(1, 'weeks'),
    '1-months': moment().subtract(1, 'months'),
    '6-months': moment().subtract(6, 'months'),
    '1-year': moment().subtract(1, 'years'),
  };
  return durationToStartTime[duration]?.utc().format('YYYY-MM-DDTHH:mm[Z]');
};

type DataParserData = {
  endTime: moment.Moment | Date | string;
  startTime: moment.Moment | Date | string;
  amount: number;
  monetaryCost: number;
};

const getDefaultData = (label: string) => ({
  updateTime: label,
  value: 0,
  cost: 0,
  filler: true,
  length: 0,
});

const filledData = (data: DataParserData[], categories: string[], timeFormat: string) => {
  const filledHours = categories.map((category) => {
    const currentCategory = moment.utc(category).format(timeFormat);
    const availableData = data.filter(
      (sd) => moment.utc(sd.endTime || undefined).format(timeFormat) === currentCategory,
    );

    if (availableData.length) {
      return {
        updateTime: category,
        value: sumBy(availableData, (adi) => adi.amount),
        cost: sumBy(availableData, (adi) => adi.monetaryCost),
        length: availableData.length,
        filler: false,
      };
    }

    return getDefaultData(category);
  });
  return filledHours.flat();
};

const setTimeData =
  (timeRange: number, duration: moment.DurationInputArg2, timeFormat: string) => (data: DataParserData[]) => {
    const time = new Array(timeRange).fill(undefined).map((val, idx) => {
      const timeToSubtract = Math.abs(idx + 1 - timeRange);
      return moment().subtract(timeToSubtract, duration).format();
    });
    if (!data.length) {
      return time.map((wd) => getDefaultData(wd));
    }
    const sortedData = data
      .filter((s) => !!s.startTime)
      .sort((a, b) =>
        new Date(a.endTime?.toString() || new Date()) < new Date(b.endTime?.toString() || new Date()) ? -1 : 1,
      );
    return filledData(sortedData, time, timeFormat);
  };

export const chartDataParsers = {
  today: setTimeData(24, 'hours', 'l HH'),
  week: setTimeData(7, 'days', 'l'),
  month: setTimeData(31, 'days', 'l'),
  sixMonths: setTimeData(7, 'months', 'YYYY/M'),
  year: setTimeData(13, 'months', 'YYYY/M'),
};
