import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';

import { Permission } from 'models/permission.enum';

import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';

import { DEFAULT_PATH } from '@routesConfig/routesConst';
import { selectPermissionByName } from '@services/auth/selectors';
import { useAppSelector } from '@services/hooks';

import WorkspaceRenameModal from './WorkspaceRenameModal';
import { useCompanyPartnership } from './sidebars/companyPartnership/useCompanyPartnership';

type Section = {
  name: string;
  items: {
    title: string;
    subtitle: string;
    value?: string;
    onClick: () => void;
    disabled?: boolean;
  }[];
};

export const WorkspaceSettingsView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const canEditCompany = useAppSelector((state) => selectPermissionByName(state, Permission.CAN_EDIT_COMPANY));
  const { partnerCompanies, partnerCompany } = useCompanyPartnership();

  const [renameModalOpen, setRenameModalOpen] = useState(false);

  const workspaceLabel = t('workspace', 'Workspace');
  const payoutDetailsLabel = t('itemDetails', '{{item}} details', { item: t('payout', 'Payout') });

  const sections: Section[] = [
    {
      name: t('itemDetails', '{{item}} details', { item: workspaceLabel }),
      items: [
        {
          title: t('itemName', '{{item}} name', { item: workspaceLabel }),
          subtitle: t('updateYourItem', 'Update your {{item}}', {
            item: `${workspaceLabel} ${t('name', 'Name')}`.toLowerCase(),
          }),
          onClick: () => setRenameModalOpen(true),
          disabled: !canEditCompany,
        },
        {
          title: t('itemDetails', '{{item}} details', { item: t('company', 'Company') }),
          subtitle: t('updateYourItem', 'Update your {{item}}', {
            item: t('companyRegistrationInformation', 'Company registration information').toLowerCase(),
          }),
          onClick: () => navigate('companyDetails'),
          disabled: !canEditCompany,
        },
      ],
    },
    {
      name: t('billingAndPayout', 'Billing and Payout'),
      items: [
        {
          title: t('paymentMethod', 'Payment method'),
          subtitle: t('manageYourSubscriptionPaymentMethod', 'Manage your subscription payment method'),
          onClick: () => navigate('paymentMethod'),
          disabled: !canEditCompany,
        },
        {
          title: payoutDetailsLabel,
          subtitle: t('updateYourItem', 'Update your {{item}}', {
            item: payoutDetailsLabel.toLowerCase(),
          }),
          onClick: () => navigate('payoutDetails'),
          disabled: !canEditCompany,
        },
      ],
    },
    {
      name: t('generalSettings', 'General settings'),
      items: [
        {
          title: t('partnerCompany', 'Partner company'),
          subtitle: t('manageOrRemoveWorkspacePartner', 'Manage or remove workspace partner'),
          value: partnerCompany?.name,
          onClick: () => navigate('companyPartnership'),
          disabled: !canEditCompany || !partnerCompanies.length,
        },
      ],
    },
  ];

  useEffect(() => {
    if (!canEditCompany) {
      navigate(DEFAULT_PATH);
    }
  }, [canEditCompany]);

  return (
    <Box display="flex" flexDirection="column" gap={5} bgcolor="white" borderRadius={2} p={4}>
      {sections.map((section) => (
        <Box
          key={section.name}
          display="flex"
          sx={
            isMobile
              ? {
                  flexDirection: 'column',
                  gap: 0,
                }
              : {
                  flexDirection: 'row',
                  gap: 10,
                }
          }
        >
          <Typography
            variant="p16b"
            py={1}
            sx={
              isMobile
                ? {
                    textAlign: 'left',
                    px: 0,
                    minWidth: 124,
                  }
                : {
                    textAlign: 'right',
                    px: 2,
                    minWidth: 320,
                  }
            }
          >
            {section.name}
          </Typography>
          <Box display="flex" flexDirection="column" gap={1} width={1}>
            {section.items.map((item) => (
              <Box
                key={item.title}
                display="flex"
                alignItems="start"
                textAlign="left"
                borderRadius={2}
                py={1}
                width={1}
                component={Button}
                textTransform="none"
                disabled={item.disabled}
                onClick={item.onClick}
                sx={{
                  '&:enabled:hover': {
                    bgcolor: '#F4F4F4',
                  },
                  '&:disabled': {
                    cursor: 'default',
                    opacity: 0.5,
                  },
                  ...(isMobile
                    ? {
                        px: 0,
                      }
                    : {
                        px: 2,
                      }),
                }}
              >
                <Box display="flex" flexDirection="column" width={1}>
                  <Typography variant="p16" color="#282828">
                    {item.title}
                  </Typography>
                  <Typography variant="p14" color="#5E5E5E">
                    {item.subtitle}
                  </Typography>
                </Box>
                {item.value && (
                  <Typography variant="p16b" color="#282828" textAlign="right" width={isMobile ? 0.5 : 1}>
                    {item.value}
                  </Typography>
                )}
              </Box>
            ))}
          </Box>
        </Box>
      ))}

      <WorkspaceRenameModal isOpen={renameModalOpen} closeModal={() => setRenameModalOpen(false)} />
      <Outlet />
    </Box>
  );
};
