import { Trans, useTranslation } from 'react-i18next';

import { FeatureDto, FeatureId } from 'models/subscriptions';
import moment from 'moment';

import { Box, Typography, useTheme } from '@mui/material';

import { BulletList } from '@components/atoms/BulletList';

type CancelSubscriptionSidebarContentProps = {
  feature: FeatureDto;
};

export const CancelSubscriptionSidebarContent = ({ feature }: CancelSubscriptionSidebarContentProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const getBillingDate = () => {
    const currentDate = moment();
    let billingDate;

    if (currentDate.date() <= 4) {
      billingDate = currentDate.date(5);
    } else {
      billingDate = currentDate.add(1, 'month').date(5);
    }

    return billingDate.format('DD.MM.YYYY');
  };

  return (
    <>
      <Typography variant="h2">
        {t('areYouSureYouWantToCancelYourSubscription', 'Are you sure you want to cancel your subscription?')}
      </Typography>
      <Box display="flex" flexDirection="column" color={theme.palette.darkGray}>
        {feature.featureId === FeatureId.DEVICE_PUBLIC_CHARGING ? (
          <>
            <Typography variant="p14">{t('pleaseNote', 'Please note:')}</Typography>
            <BulletList>
              <Typography variant="p14">
                <Trans
                  i18nKey="publicCharging.featureWillBeDisabled"
                  defaults="The <1>public charging feature</1> will be disabled."
                  components={{
                    1: <Typography variant="p14b" />,
                  }}
                />
              </Typography>
              <Typography variant="p14">
                <Trans
                  i18nKey="publicCharging.cancellationWarning"
                  defaults="Your <3>sites will no longer be accessible</3> to EV owners through <1><2>charge.vool.com</2></1>."
                  components={{
                    1: (
                      <Typography
                        variant="p14"
                        color={theme.palette.mediumVermilion}
                        sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                      />
                    ),
                    2: (
                      <a
                        href="https://charge.vool.com/"
                        target="_blank"
                        rel="noreferrer"
                        aria-label="Link to public charging portal"
                      />
                    ),
                    3: <Typography variant="p14b" />,
                  }}
                />
              </Typography>
            </BulletList>
          </>
        ) : (
          feature.description
        )}
      </Box>
      <Typography variant="p14" color={theme.palette.darkGray}>
        {t('youWillBeBilledOnDate', 'You will be billed on {{date}}', { date: getBillingDate() })}
      </Typography>
    </>
  );
};
