import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FeatureDto } from 'models/subscriptions';

import { Box } from '@mui/material';

import { StyledButton } from '@components/atoms/StyledButton';
import { useSubscribeToFeatureMutation } from '@services/subscriptions/endpoints';

import { SubscriptionPrice } from './SubscriptionPrice';
import { SubscriptionTermsOfUseBox } from './SubscriptionTermsOfUseBox';

type StartSubscriptionSidebarFooterProps = {
  feature: FeatureDto;
  hasDiscount: boolean;
  onClose: () => void;
};

export const StartSubscriptionSidebarFooter = ({
  feature,
  hasDiscount,
  onClose,
}: StartSubscriptionSidebarFooterProps) => {
  const { t } = useTranslation();
  const [subscribeToFeature, { isLoading }] = useSubscribeToFeatureMutation();
  const [termsAccepted, setTermsAccepted] = useState(false);

  const handleClick = async () => {
    try {
      await subscribeToFeature({ featureUuid: feature.uuid }).unwrap();
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <SubscriptionTermsOfUseBox termsAccepted={termsAccepted} onTermsAcceptedChange={setTermsAccepted} />
      <Box display="flex" alignItems="center" gap={2}>
        <SubscriptionPrice feature={feature} hasDiscount={hasDiscount} flex={1} />
        <StyledButton color="success" onClick={handleClick} disabled={!termsAccepted || isLoading}>
          {t('subscribe', 'Subscribe')}
        </StyledButton>
      </Box>
    </>
  );
};
