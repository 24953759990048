import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import moment from 'moment';

import { Box, Skeleton, Tab, Table, TableBody, TableCell, TableContainer, Tabs, Typography } from '@mui/material';

import { CardContainer } from '@components/atoms/CardContainer';
import { StyledButton } from '@components/atoms/StyledButton';
import { StyledSkeletonTableRow } from '@components/atoms/StyledTableRow';
import { useGetPartnerCompaniesQuery } from '@services/partners/endpoints';
import { useGetAllSitesQuery } from '@services/sites/endpoints';

import { PartnerWorkspaceNameContent } from './PartnerWorkspaceNameContent';

enum WorkspacesDashboardTab {
  NEW_PARTNER_COMPANIES = 'newPartnerCompanies',
}

const PartnersWorkspacesRowSkeleton = () => (
  <StyledSkeletonTableRow>
    <TableCell width={180}>
      <Box display="flex" flexDirection="column">
        <Skeleton variant="text" animation="wave" width="60%" />
        <Skeleton variant="text" animation="wave" width="80%" />
      </Box>
    </TableCell>
  </StyledSkeletonTableRow>
);

const PartnersWorkspacesTableSkeleton = () => {
  return (
    <TableContainer component={CardContainer} sx={{ p: 0 }}>
      <Table>
        <TableBody>
          {Array.from(Array(6).keys()).map(() => (
            <PartnersWorkspacesRowSkeleton key={self.crypto.randomUUID()} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const PartnersWorkspacesDashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(WorkspacesDashboardTab.NEW_PARTNER_COMPANIES);

  const { data: partnerCompanies = [], isLoading } = useGetPartnerCompaniesQuery();
  useGetAllSitesQuery();

  const newPartnerCompanies = useMemo(
    () => [...partnerCompanies].sort((a, b) => moment(b.addTime).diff(moment(a.addTime))).slice(0, 10),
    [partnerCompanies],
  );

  return (
    <Box display="flex" flexDirection="column" width={1} gap={1}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="p18">
          {t('workspaces', 'Workspaces')} • {partnerCompanies.length}
        </Typography>
        <StyledButton color="success" onClick={() => navigate('/workspaces')}>
          {t('viewAll', 'View all')}
        </StyledButton>
      </Box>
      <CardContainer width={1} mb={2} sx={{ p: 0 }}>
        <Tabs
          value={activeTab}
          onChange={(e, newValue) => setActiveTab(newValue)}
          TabIndicatorProps={{
            sx: {
              backgroundColor: '#EB4E20',
            },
          }}
          sx={{
            width: 1,
            borderBottom: 1,
            borderColor: 'divider',
            '& .MuiButtonBase-root.Mui-selected': {
              color: '#EB4E20',
            },
          }}
        >
          <Tab
            label={
              <Typography variant="p16" textTransform="none">
                {t('recentWorkspaces', 'Recent workspaces')} • {newPartnerCompanies.length}
              </Typography>
            }
            value={WorkspacesDashboardTab.NEW_PARTNER_COMPANIES}
          />
        </Tabs>
        {isLoading ? (
          <PartnersWorkspacesTableSkeleton />
        ) : (
          <Box display="flex" flexDirection="column" gap={1}>
            {newPartnerCompanies.map((company) => (
              <Box
                component={Link}
                key={company.uuid}
                to={`/workspaces/${company.uuid}`}
                py={1}
                px={2}
                sx={{
                  borderBottom: '1px solid #E0E0E0',
                  '&:last-child': {
                    borderBottom: 'none',
                  },
                }}
              >
                <PartnerWorkspaceNameContent company={company} />
              </Box>
            ))}
          </Box>
        )}
      </CardContainer>
    </Box>
  );
};
