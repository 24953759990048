import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { skipToken } from '@reduxjs/toolkit/query';
import jsCookie from 'js-cookie';
import { CompanyRole } from 'models/role.enum';

import Button from '@components/atoms/Button';
import { OverflowSpinner } from '@components/spinner/OverflowSpinner';
import { chargePortalUrl } from '@config/consts';
import largeSuccessIcon from '@images/icons/Large_success.svg';
import largeWarningIcon from '@images/icons/Large_warning.svg';
import { DEFAULT_PATH } from '@routesConfig/routesConst';
import { useGetInviteDataQuery, useSelfQuery } from '@services/auth/endpoints';
import { selectToken, selectUser } from '@services/auth/selectors';
import { useJoinByInviteMutation } from '@services/companies/endpoints';

export const useInviteToken = (inviteToken: string | undefined | null) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const self = useSelector(selectUser);
  const selfToken = useSelector(selectToken);

  const {
    data: { data: inviteData } = {},
    isSuccess,
    isLoading,
    isError,
  } = useGetInviteDataQuery(inviteToken ?? skipToken);
  const [joinByInvite, { isLoading: isLoadingJoin }] = useJoinByInviteMutation();
  const isLoggedInAsInvitedUser = inviteData && self && inviteData.userUuid === self.uuid;
  const isPublicMember = inviteData?.role === CompanyRole.PUBLIC_MEMBER;
  const { isLoading: isLoadingSelf } = useSelfQuery(undefined, { skip: !inviteData || !selfToken });
  const hasAlreadyJoined = inviteData && self?.activeCompanyUuid === inviteData.companyUuid;

  const getRelevantPathname = () => {
    if (!inviteData?.activatedFlag) {
      return '/register';
    }
    if (inviteData.googleVerified || inviteData.appleId) {
      return '/welcome';
    }
    return '/login';
  };

  const continueToWorkspace = async () => {
    if (!hasAlreadyJoined && inviteData) {
      // There was no login/register, so no join has happened previously
      await joinByInvite(inviteData.inviteToken);
    }
    if (isPublicMember && chargePortalUrl) {
      const domain = chargePortalUrl.split('.').slice(-2).join('.');
      jsCookie.set('cu_temporaryToken', 'invalid_token', {
        sameSite: 'None',
        secure: true,
        domain: `.${domain}`,
      });
      jsCookie.set('cu_refreshToken', jsCookie.get('ad_refreshToken')!, {
        sameSite: 'None',
        secure: true,
        domain: `.${domain}`,
      });
      window.location.href = chargePortalUrl;
      return;
    }
    navigate(DEFAULT_PATH);
  };

  const getContinueButtonLabel = () => {
    if (!hasAlreadyJoined) {
      return t('joinWorkspace', 'Join workspace');
    }
    if (isPublicMember) {
      return t('continueToChargingPortal', 'Continue to charging portal');
    }
    return t('continueToWorkspace', 'Continue to workspace');
  };

  const getUserInviteMessage = () => {
    if (!hasAlreadyJoined) {
      return (
        <Trans
          i18nKey="clickTheButtonBelowToJoinTheWorkspace"
          defaults="Click the button below to join the <strong>{{ workspaceName }}</strong> workspace."
          values={{ workspaceName: inviteData?.companyName }}
          components={{ strong: <strong /> }}
        />
      );
    }
    if (isPublicMember) {
      return (
        <Trans
          i18nKey="youAreNowAMemberOfTheWorkspaceAsPublicMember"
          defaults="You’re now a member of the <strong>{{ workspaceName }}</strong> workspace and you can see and use their chargers."
          values={{ workspaceName: inviteData.companyName }}
          components={{ strong: <strong /> }}
        />
      );
    }
    return (
      <Trans
        i18nKey="youAreNowAMemberOfTheWorkspace"
        defaults="You’re now a member of the <strong>{{ workspaceName }}</strong> workspace."
        values={{ workspaceName: inviteData?.companyName }}
        components={{ strong: <strong /> }}
      />
    );
  };

  const getOutcomeContent = () => {
    if (!inviteToken) {
      return null;
    }
    if (isLoading || isLoadingSelf) {
      return <OverflowSpinner backgroundColor="transparent" />;
    }
    if (isError) {
      // TODO? What about other errors?
      return (
        <div className="w-11/12 max-w-md space-y-4 p-9 md:w-full">
          <img src={largeWarningIcon} alt="Warning" />
          <div className="font-everett text-3xl">{t('thisInviteLinkIsNotValid', 'This invite link is not valid.')}</div>
          <p className="font-poppins text-base">
            {t(
              'thisInviteLinkIsNotValidErrorText',
              'This may be for a number of reasons, such as it already being used, or being revoked.',
            )}
          </p>
          <p className="font-poppins text-base">
            {t(
              'ifYouBelieveThisToBeAnErrorText',
              'If you believe this to be an error, please contact your workspace administrator.',
            )}
          </p>
        </div>
      );
    }
    if (isLoggedInAsInvitedUser) {
      return (
        <div className="w-11/12 max-w-md space-y-4 p-9 md:w-full">
          <img src={largeSuccessIcon} alt="Success" />
          <div className="font-everett text-3xl">{t('welcome!', 'Welcome!')}</div>
          <p className="pb-4 font-poppins text-base">{getUserInviteMessage()}</p>
          <Button variant="primary" bigger className="w-full" disabled={isLoadingJoin} onClick={continueToWorkspace}>
            {getContinueButtonLabel()}
          </Button>
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    if (!inviteToken || !isSuccess) {
      return;
    }
    const passwordResetPathnames = ['/reset-password', '/forgot-password'];
    const relevantPathname = getRelevantPathname();
    if (
      !passwordResetPathnames.some((prefix) => pathname.startsWith(prefix)) &&
      !pathname.startsWith(relevantPathname)
    ) {
      navigate(`${relevantPathname}?inviteToken=${inviteToken}`, { replace: true });
    }
  }, [isSuccess]);

  const InviteHeader = () => (
    <div className="mb-6 w-full">
      <div className="mb-2 font-everett text-3xl">{t('youHaveBeenInvited', 'You’ve been invited!')}</div>
      <span className="font-poppins">
        {inviteData?.activatedFlag ? (
          <>
            <Trans
              i18nKey="signInToJoinTheWorkspace"
              defaults="Sign in to join the <strong>{{ workspaceName }}</strong> workspace."
              values={{ workspaceName: inviteData?.companyName }}
              components={{ strong: <strong /> }}
            />
            {!!inviteData?.googleVerified && (
              <div className="mt-4">
                <Trans
                  i18nKey="pleaseBeSureToUseYourEmailGoogleAccount"
                  defaults="Note: Please be sure to use your <strong>{{ email }}</strong> Google account."
                  values={{ email: inviteData?.email }}
                  components={{ strong: <strong /> }}
                />
              </div>
            )}
          </>
        ) : (
          <Trans
            i18nKey="coupleMoreStepsJoinWorkspaceText"
            defaults="Just a couple more steps before you can join the <strong>{{ workspaceName }}</strong> workspace."
            values={{ workspaceName: inviteData?.companyName }}
            components={{ strong: <strong /> }}
          />
        )}
      </span>
    </div>
  );

  const outcomeContent = getOutcomeContent();

  return {
    inviteData,
    inviteHeader: inviteData ? InviteHeader : null,
    outcomeComponent: outcomeContent ? () => outcomeContent : null,
  };
};

export const useInviteTokenFromQueryParam = () => {
  const [searchParams] = useSearchParams();
  const inviteToken = searchParams.get('inviteToken');
  return useInviteToken(inviteToken);
};
