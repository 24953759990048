import { Dispatch, ReactNode, SetStateAction, createContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { StyledMenuItemProps } from '@components/atoms/StyledMenuItem';
import { FilterMenuOption } from '@components/filters';
import {
  FiltersBaseContextType,
  FiltersDataContextType,
  getFiltersBaseContextDefaultValues,
} from '@components/filters/context/FiltersContext';
import { useDataFiltering } from '@components/filters/hooks/useDataFiltering';
import { useDeviceFirmwareMenu } from '@views/reporting/hooks/useDeviceFirmwareMenu';
import { useDeviceStatusMenu } from '@views/reporting/hooks/useDeviceStatusMenu';
import { useDeviceTypeMenu } from '@views/reporting/hooks/useDeviceTypeMenu';
import { useSiteMenu } from '@views/reporting/hooks/useSiteMenu';
import { useWorkspaceMenu } from '@views/reporting/hooks/useWorkspaceMenu';

import { DeviceWithStats } from './devicesAndGroupsTypes';
import { useDevicesAndGroups } from './siteDevicesAndGroupsHelpers/useDevicesAndGroups';

export type DevicesAndGroupsFilterMenuOption = Extract<
  FilterMenuOption,
  'status' | 'workspace' | 'site' | 'type' | 'firmware'
>;

type DevicesAndGroupsContextType = FiltersBaseContextType<DevicesAndGroupsFilterMenuOption> &
  FiltersDataContextType<DeviceWithStats> &
  ReturnType<typeof useDevicesAndGroups> & {
    siteUuid?: string;
    companyUuid?: string;
  };

export const DevicesAndGroupsContext = createContext<DevicesAndGroupsContextType>({
  ...getFiltersBaseContextDefaultValues<DeviceWithStats>(),
  filterOptionsMap: {
    status: [],
    workspace: [],
    site: [],
    type: [],
    firmware: [],
  },
  siteUuid: undefined,
  companyUuid: undefined,
  lmcMultipointsWithStats: [],
  chargersWithStats: [],
  invertersWithStats: [],
  isFetchingGetSiteInvertersSummary: false,
  selectedCharger: undefined,
  selectedLmcMultipoint: undefined,
  refetchDevices: () => {},
  refetchInverters: () => {},
});

export const DevicesAndGroupsProvider = ({
  selectedDuration,
  children,
}: {
  selectedDuration?: string;
  children?: ReactNode;
}) => {
  const { siteUuid, companyUuid } = useParams();

  const { deviceStatusMenuOptions, setDeviceStatusMenuOptions } = useDeviceStatusMenu();
  const { workspaceMenuOptions, setWorkspaceMenuOptions } = useWorkspaceMenu();
  const { siteMenuOptions, setSiteMenuOptions } = useSiteMenu();
  const { deviceTypeMenuOptions, setDeviceTypeMenuOptions } = useDeviceTypeMenu();
  const { deviceFirmwareMenuOptions, setDeviceFirmwareMenuOptions } = useDeviceFirmwareMenu();

  const filterOptionsMap: Partial<Record<DevicesAndGroupsFilterMenuOption, StyledMenuItemProps[]>> = useMemo(
    () => ({
      status: deviceStatusMenuOptions,
      ...(!companyUuid && { workspace: workspaceMenuOptions }),
      ...(!siteUuid && { site: siteMenuOptions }),
      type: deviceTypeMenuOptions,
      firmware: deviceFirmwareMenuOptions,
    }),
    [deviceStatusMenuOptions, workspaceMenuOptions, siteMenuOptions, deviceTypeMenuOptions, deviceFirmwareMenuOptions],
  );

  const filterMap: Record<DevicesAndGroupsFilterMenuOption, Dispatch<SetStateAction<StyledMenuItemProps[]>>> = {
    status: setDeviceStatusMenuOptions,
    workspace: setWorkspaceMenuOptions,
    site: setSiteMenuOptions,
    type: setDeviceTypeMenuOptions,
    firmware: setDeviceFirmwareMenuOptions,
  };

  const uuidFilterMap: Record<DevicesAndGroupsFilterMenuOption, keyof DeviceWithStats> = {
    status: 'computedStatus',
    workspace: 'companyUuid',
    site: 'siteUuid',
    type: 'deviceType',
    firmware: 'firmwareVersion',
  };

  const dataFiltering = useDataFiltering<DeviceWithStats, DevicesAndGroupsFilterMenuOption>({
    filterOptionsMap,
    uuidFilterMap,
    filterMap,
    initialOrderBy: 'displayName',
  });

  const { searchQuery, filterInclusivityMap, anyActiveFilters, filterOptionsMapChecked, searchFilter } = dataFiltering;

  const devicesAndGroupsData = useDevicesAndGroups({
    siteUuid,
    companyUuid,
    selectedDuration,
  });

  const { lmcMultipointsWithStats, chargersWithStats, invertersWithStats } = devicesAndGroupsData;

  const filterDeps = [searchQuery, filterOptionsMapChecked, filterInclusivityMap, anyActiveFilters];
  const filteredLmcMultipointsWithStats = useMemo(
    () => searchFilter(lmcMultipointsWithStats),
    [...filterDeps, lmcMultipointsWithStats],
  );
  const filteredChargersWithStats = useMemo(() => searchFilter(chargersWithStats), [...filterDeps, chargersWithStats]);
  const filteredInvertersWithStats = useMemo(
    () => searchFilter(invertersWithStats),
    [...filterDeps, invertersWithStats],
  );

  const value = useMemo(
    () => ({
      siteUuid,
      companyUuid,
      filterOptionsMap,
      ...dataFiltering,
      ...devicesAndGroupsData,
      lmcMultipointsWithStats: filteredLmcMultipointsWithStats,
      chargersWithStats: filteredChargersWithStats,
      invertersWithStats: filteredInvertersWithStats,
    }),
    [siteUuid, companyUuid, filterOptionsMap, dataFiltering, devicesAndGroupsData],
  );

  return <DevicesAndGroupsContext.Provider value={value}>{children}</DevicesAndGroupsContext.Provider>;
};
