import { FeatureDto, FeatureId } from 'models/subscriptions';

import { Box, Typography, useTheme } from '@mui/material';

import SidebarBase from '@components/sidebars/SidebarBase';
import { ReactComponent as CloseIcon } from '@images/icons/Close.svg';
import PublicChargingHero from '@images/icons/subscriptions/public-charging-hero.png';
import ScheduledReportsHero from '@images/icons/subscriptions/scheduled-reports-hero.png';
import ThirdPartyChargersHero from '@images/icons/subscriptions/third-party-chargers-hero.png';

import { CancelSubscriptionSidebarContent } from './CancelSubscriptionSidebarContent';
import { CancelSubscriptionSidebarFooter } from './CancelSubscriptionSidebarFooter';
import { StartSubscriptionSidebarContent } from './StartSubscriptionSidebarContent';
import { StartSubscriptionSidebarFooter } from './StartSubscriptionSidebarFooter';

type SubscriptionsSidebarProps = {
  feature: FeatureDto;
  hasSubscription: boolean;
  hasDiscount: boolean;
  hasTrial: boolean;
  open: boolean;
  handleClose: () => void;
};

const heroMap: Record<FeatureId, string> = {
  'device.public-charging': PublicChargingHero,
  'company.report-schedules': ScheduledReportsHero,
  'device.third-party-chargers': ThirdPartyChargersHero,
};

export const SubscriptionsSidebar = ({
  feature,
  hasSubscription,
  hasDiscount,
  hasTrial,
  open,
  handleClose,
}: SubscriptionsSidebarProps) => {
  const theme = useTheme();

  return (
    <SidebarBase isOpen={open} onClose={handleClose} widthClass="sm:max-w-[616px]">
      <Box display="flex" flexDirection="column" gap={2} height={1}>
        <Box display="flex" flexDirection="column" gap={4} p="24px 24px 16px 24px">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h3">{feature.name}</Typography>
            <CloseIcon onClick={handleClose} />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" flex={1} overflow="scroll" gap={4} p="0 24px 24px 24px">
          <img src={heroMap[feature.featureId]} alt={feature.name} />
          {hasSubscription ? (
            <CancelSubscriptionSidebarContent feature={feature} />
          ) : (
            <StartSubscriptionSidebarContent feature={feature} hasTrial={hasTrial} />
          )}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          padding="16px 24px"
          borderTop={`1px solid ${theme.palette.lightGray}`}
        >
          {hasSubscription ? (
            <CancelSubscriptionSidebarFooter feature={feature} onGoBack={handleClose} />
          ) : (
            <StartSubscriptionSidebarFooter feature={feature} hasDiscount={hasDiscount} onClose={handleClose} />
          )}
        </Box>
      </Box>
    </SidebarBase>
  );
};
