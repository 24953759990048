import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { useGetFeaturesQuery } from '@services/subscriptions/endpoints';

import { FeatureCard } from './FeatureCard';

export const WorkspaceSubscriptionsView = () => {
  const { t } = useTranslation();
  const { data: features } = useGetFeaturesQuery();

  return (
    <Box display="flex" flexDirection="column" gap={5}>
      {/* <SubscriptionsTotalCards features={features} /> */}
      <Box display="flex" flexDirection="column" gap={2}>
        <Typography variant="p18">{t('subscriptions', 'Subscriptions')}</Typography>
        {features?.map((feature) => (
          <FeatureCard key={feature.uuid} feature={feature} />
        ))}
      </Box>
    </Box>
  );
};
