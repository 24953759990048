import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import TimePeriodSelectorHeader from '@components/filterComponents/TimePeriodSelectorHeader';
import { selectUser } from '@services/auth/selectors';
import { durationToTimeFrame, getFormattedStartTimeForDuration } from '@services/transactions/chartDataParser';

import ChargingSessionsSummary from './ChargingSessionsSummary';
import { DashboardSummary } from './DashboardSummary';
import DevicesSummary from './DevicesSummary';

const DashboardView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isAdmin = useSelector(selectUser)?.admin;

  const [selectedDuration, setSelectedDuration] = useState();

  const periodStartTime = useMemo(() => getFormattedStartTimeForDuration(selectedDuration), [selectedDuration]);

  const timeFrame = durationToTimeFrame[selectedDuration] || durationToTimeFrame['1-months'];

  useEffect(() => {
    if (isAdmin) {
      navigate('/sites');
    }
  }, [isAdmin]);

  return (
    <>
      <DashboardSummary />
      <TimePeriodSelectorHeader
        selectedDuration={selectedDuration}
        setSelectedDuration={setSelectedDuration}
        labelPrefix={t('overviewFor', 'Overview for')}
        storageKey="dashboardDuration"
        withoutAll
      />
      <div className="flex flex-col gap-y-4">
        <ChargingSessionsSummary timeFrame={timeFrame} periodStartTime={periodStartTime} />
        <DevicesSummary timeFrame={timeFrame} periodStartTime={periodStartTime} />
      </div>
    </>
  );
};

export default DashboardView;
