import { CompanyWithUser } from 'models/company';

import apiSlice from '@services/api';

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPartnerCompanies: builder.query<CompanyWithUser[], void>({
      query: () => ({
        url: `partners/companies`,
        method: 'GET',
      }),
      transformResponse: (response: { data: CompanyWithUser[] }) => response.data,
      providesTags: ['PartnerContext'],
    }),
    updateCompanyPartnership: builder.mutation<{ success: boolean }, string | undefined>({
      query: (partnerCompanyUuid) => ({
        url: `partners/companyPartnership`,
        method: 'PUT',
        body: { partnerCompanyUuid },
      }),
      invalidatesTags: ['CompanyPartnership'],
    }),
    getCompanyPartnership: builder.query<{ companyUuid: string; partnerCompanyUuid: string }, void>({
      query: () => ({
        url: `partners/companyPartnership`,
        method: 'GET',
      }),
      providesTags: ['CompanyPartnership'],
      transformResponse: (response: { companyPartnership: { companyUuid: string; partnerCompanyUuid: string } }) =>
        response.companyPartnership,
    }),
  }),
});

export const { useGetPartnerCompaniesQuery, useUpdateCompanyPartnershipMutation, useGetCompanyPartnershipQuery } =
  extendedApi;
export const partnerApi = extendedApi;
