import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { skipToken } from '@reduxjs/toolkit/query';
import { SetVariableAttributeStatus } from 'models/device';

import { Box, IconButton, InputAdornment } from '@mui/material';

import { StyledTextField } from '@components/atoms/StyledTextField';
import EditingSidebarBase from '@components/sidebars/EditingSidebarBase';
import clearIcon from '@images/icons/ClearIcon.svg';
import { ReactComponent as RebootRequiredIcon } from '@images/icons/RebootRequired.svg';
import searchIcon from '@images/icons/Search.svg';
import checkIcon from '@images/icons/check.svg';
import { useGetOcppVariablesQuery, useSetOcppVariablesMutation } from '@services/devices/endpoints';

import { NoOcppVariablesToShowBox } from './NothingToShowBox';
import { FailedIcon } from './OcppVariablesSidebar.common';
import { OcppVariablesTable } from './OcppVariablesTable';

export const OcppVariablesSidebar = () => {
  const { t } = useTranslation();
  const { deviceUuid } = useParams();

  const { data, isLoading } = useGetOcppVariablesQuery(deviceUuid ?? skipToken, { refetchOnMountOrArgChange: true });
  const [setOcppVariables, { isLoading: isLoadingSetVariables }] = useSetOcppVariablesMutation();

  const [searchQuery, setSearchQuery] = useState('');
  const [variableValues, setVariableValues] = useState<Record<string, string>>({});
  const [variableStatuses, setVariableStatuses] = useState<Record<string, SetVariableAttributeStatus>>({});

  useEffect(() => {
    if (data) {
      setVariableValues((prev) => ({
        ...prev,
        ...Object.fromEntries(
          data
            .filter((item) => !(item.variable in variableStatuses))
            .map((item) => [item.variable, item.attributeValue ?? '']),
        ),
      }));
    }
  }, [data]);

  const setVariableValue = (variable: string, value: string) =>
    setVariableValues((prev) => ({ ...prev, [variable]: value }));

  const anyDataChanged = !!data?.some((item) => (variableValues[item.variable] ?? '') !== (item.attributeValue ?? ''));

  const saveValues = async () => {
    if (deviceUuid && data && anyDataChanged) {
      const changedVariables = data
        .filter((item) => (variableValues[item.variable] ?? '') !== (item.attributeValue ?? ''))
        .map((item) => ({ variable: item.variable, value: variableValues[item.variable] }));
      if (changedVariables.length > 0) {
        try {
          const response = await setOcppVariables({ deviceUuid, variables: changedVariables }).unwrap();
          setVariableStatuses((prev) => ({
            ...prev,
            ...Object.fromEntries(response.map((item) => [item.variable, item.attributeStatus])),
          }));
        } catch {
          setVariableStatuses((prev) => ({
            ...prev,
            ...Object.fromEntries(changedVariables.map((item) => [item.variable, SetVariableAttributeStatus.FAILED])),
          }));
        }
      }
    }
    return false;
  };

  const filteredData = searchQuery
    ? data?.filter((item) => item.variable.toLowerCase().includes(searchQuery.toLowerCase()))
    : data;

  const statusCounts = useMemo(
    () =>
      Object.values(variableStatuses).reduce(
        (acc, status) => {
          if (status === SetVariableAttributeStatus.ACCEPTED) {
            return { ...acc, updated: acc.updated + 1 };
          }
          if (status === SetVariableAttributeStatus.REBOOT_REQUIRED) {
            return { ...acc, reboot: acc.reboot + 1 };
          }
          return { ...acc, failed: acc.failed + 1 };
        },
        { updated: 0, reboot: 0, failed: 0 },
      ),
    [variableStatuses],
  );

  return (
    <EditingSidebarBase
      title={t('ocppVariables', 'OCPP variables')}
      saveLabel={t('updateValues', 'Update values')}
      compactButtons
      discardLabel={t('close', 'Close')}
      onSaveValues={saveValues}
      wide
      anyDataChanged={anyDataChanged}
      updateLoading={isLoadingSetVariables}
      containerClassName="flex flex-col"
      containerBottomPadding="mb-[34px]"
      footer={() => (
        <Box sx={{ float: 'left', width: '340px', height: '40px', display: 'flex', alignItems: 'center', gap: '10px' }}>
          {!!statusCounts.updated && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <img src={checkIcon} width="24" alt="" />
              {statusCounts.updated} {t('updated', 'Updated').toLowerCase()}
            </Box>
          )}
          {!!statusCounts.reboot && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <Box sx={{ color: '#EB4E20' }}>
                <RebootRequiredIcon />
              </Box>
              {statusCounts.reboot} {t('reboot', 'Reboot').toLowerCase()}
            </Box>
          )}
          {!!statusCounts.failed && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <FailedIcon />
              {statusCounts.failed} {t('failed', 'Failed').toLowerCase()}
            </Box>
          )}
        </Box>
      )}
    >
      <StyledTextField
        placeholder={t('searchForVariable', 'Search for variable')}
        fullWidth
        value={searchQuery}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {searchQuery ? (
                <IconButton onClick={() => setSearchQuery('')}>
                  <img src={clearIcon} alt="clear" />
                </IconButton>
              ) : (
                <img src={searchIcon} alt="search" />
              )}
            </InputAdornment>
          ),
        }}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      {!filteredData?.length && !isLoading ? (
        <NoOcppVariablesToShowBox withSearchQuery={!!searchQuery} />
      ) : (
        <OcppVariablesTable
          data={filteredData}
          isLoading={isLoading}
          variableValues={variableValues}
          setVariableValue={setVariableValue}
          variableStatuses={variableStatuses}
        />
      )}
    </EditingSidebarBase>
  );
};
