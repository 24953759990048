import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ConnectorComputedStatus, DeviceStatus } from 'models/device.enums';

import { Box } from '@mui/material';

import { SummaryCard } from '@components/cards/SummaryCard';
import { useGetAllDevicesQuery } from '@services/devices/endpoints';

export const PartnersSummary = () => {
  const { t } = useTranslation();

  const { data } = useGetAllDevicesQuery({ forBackoffice: true });

  const chargersCurrentlyCharging = useMemo(
    () =>
      (data?.devices ?? []).filter(
        (device) =>
          device.status === DeviceStatus.ONLINE &&
          device.stateOfConnectors?.some((connector) => connector.computedStatus === ConnectorComputedStatus.CHARGING),
      ),
    [data],
  );

  return (
    <Box display="flex" flexWrap="wrap" alignItems="stretch" mb={5} gap={2}>
      <SummaryCard label={t('totalDevices', 'Total devices')} value={data?.devices.length ?? 0} />
      <SummaryCard
        label={t('chargersCurrentlyCharging', 'Chargers currently charging')}
        value={chargersCurrentlyCharging.length}
      />
    </Box>
  );
};
