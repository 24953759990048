import {
  Device,
  GetDeviceVariableResponseItem,
  LmcChargerCommunicationStatus,
  SetDeviceVariableResponseItem,
} from 'models/device';

import apiSlice from '@services/api';

export const devicesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    checkDevice: builder.mutation({
      query: ({ number }) => ({
        url: '/devices/check-device',
        method: 'POST',
        body: { number },
      }),
    }),
    addDevice: builder.mutation({
      query: ({
        isOtherDevice = false,
        number,
        pin,
        name = null,
        siteUuid = null,
        gridConnection = null,
        configOfConnectors = null,
        maxPowerKw = null,
        lmcMultipointUuid = null,
      }) => ({
        url: '/devices',
        method: 'POST',
        body: {
          isOtherDevice,
          number,
          pin,
          name,
          siteUuid,
          gridConnection,
          configOfConnectors,
          maxPowerKw,
          lmcMultipointUuid,
        },
      }),
    }),
    deleteDevice: builder.mutation({
      query: (uuid) => ({
        url: `/devices/${uuid}`,
        method: 'DELETE',
      }),
    }),
    updateDevice: builder.mutation({
      query: ({
        uuid,
        name = undefined,
        siteUuid = undefined,
        gridConnection = undefined,
        lmcMultipointUuid = undefined,
        communicatesViaLmc = undefined,
        firmwareUpdateStatus = undefined,
      }) => ({
        url: `/devices/${uuid}`,
        method: 'PUT',
        body: {
          name,
          siteUuid,
          gridConnection,
          lmcMultipointUuid,
          communicatesViaLmc,
          firmwareUpdateStatus,
        },
      }),
    }),
    getDevice: builder.query({
      query: ({ uuid }) => ({
        url: `/devices/${uuid}`,
        method: 'GET',
      }),
    }),
    getDeviceStatus: builder.query({
      query: (deviceUuid) => ({
        url: `/devices/${deviceUuid}/status`,
        method: 'GET',
      }),
    }),
    getDevices: builder.query<{ devices: Device[] }, { companyUuid?: string }>({
      query: ({ companyUuid }) => ({
        url: '/devices',
        method: 'GET',
        params: { companyUuid },
      }),
      providesTags: ['CompanyContext'],
    }),
    getAllDevices: builder.query<{ devices: Device[] }, { forBackoffice?: boolean }>({
      query: ({ forBackoffice }) => `/devices/all${forBackoffice ? '?forBackoffice=true' : ''}`,
      providesTags: ['PartnerContext'],
    }),
    startCharging: builder.mutation({
      query: ({ deviceUuid, connectorId }) => ({
        url: '/ocpp/start/byOwner',
        method: 'POST',
        body: { deviceUuid, connectorId },
      }),
    }),
    stopCharging: builder.mutation({
      query: ({ deviceSerialNumber, connectorId }) => ({
        url: '/ocpp/stop?userContext=device',
        method: 'POST',
        body: { deviceSerialNumber, connectorId },
      }),
    }),
    getDeviceTransactions: builder.query({
      query: ({ deviceSerialNr, timeFrame = 7, timeFrameUnit = 'days', aggregation = '12h' }) => ({
        url: `/graphs/device-transactions/${deviceSerialNr}`,
        method: 'POST',
        body: { timeFrame, timeFrameUnit, aggregation },
      }),
    }),
    getDeviceTransactionsSummary: builder.query({
      query: ({ uuid, timeFrame }) => ({
        url: `/devices/${uuid}/summary?timeFrame=${timeFrame}`,
        method: 'GET',
      }),
    }),
    getOcppVariable: builder.query({
      query: ({ deviceUuid, component, variable }) => ({
        url: `/ocpp/getVariable?deviceUuid=${deviceUuid}&component=${encodeURIComponent(
          component ?? '',
        )}&variable=${encodeURIComponent(variable)}`,
        method: 'GET',
      }),
    }),
    setOcppVariable: builder.mutation({
      query: ({ deviceUuid, component, variable, value }) => ({
        url: '/ocpp/setVariable',
        method: 'POST',
        body: { deviceUuid, component, variable, value },
      }),
    }),
    getOcppVariables: builder.query<GetDeviceVariableResponseItem[], string>({
      query: (deviceUuid) => `/devices/${deviceUuid}/ocpp-variables`,
      transformResponse: (response: { data: GetDeviceVariableResponseItem[] }) => response.data,
      providesTags: ['OcppVariables'],
    }),
    setOcppVariables: builder.mutation<
      SetDeviceVariableResponseItem[],
      { deviceUuid: string; variables: { variable: string; value: string }[] }
    >({
      query: ({ deviceUuid, variables }) => ({
        url: `/devices/${deviceUuid}/ocpp-variables`,
        method: 'PUT',
        body: { variables },
      }),
      transformResponse: (response: { data: SetDeviceVariableResponseItem[] }) => response.data,
      invalidatesTags: ['OcppVariables'],
    }),
    getOcppVariablesDef: builder.query({
      query: (deviceUuid) => ({
        url: `/ocpp/variablesDef?deviceUuid=${deviceUuid}`,
        method: 'GET',
      }),
    }),
    getLmcMultipoints: builder.query({
      query: (lmcDeviceUuid) => ({
        url: `/devices/${lmcDeviceUuid}/lmcMultipoints`,
        method: 'GET',
      }),
    }),
    addLmcMultipoint: builder.mutation({
      query: ({ lmcDeviceUuid, name, multipointId, currentLimit }) => ({
        url: `/devices/${lmcDeviceUuid}/lmcMultipoints`,
        method: 'POST',
        body: { name, multipointId, currentLimit },
      }),
    }),
    deleteLmcMultipoint: builder.mutation({
      query: (multipointUuid) => ({
        url: `/devices/lmcMultipoints/${multipointUuid}`,
        method: 'DELETE',
      }),
    }),
    updateLmcMultipoint: builder.mutation({
      query: ({ multipointUuid, name, multipointId, currentLimit }) => ({
        url: `/devices/lmcMultipoints/${multipointUuid}`,
        method: 'PUT',
        body: { name, multipointId, currentLimit },
      }),
    }),
    resetDevice: builder.mutation({
      query: (uuid) => ({
        url: `/ocpp/reset`,
        method: 'POST',
        body: { uuid, type: 'Immediate' },
      }),
    }),
    updateDeviceFirmware: builder.mutation({
      query: (uuid) => ({
        url: '/ocpp/updateFirmware',
        method: 'POST',
        body: { uuid },
      }),
    }),
    getDeviceConfiguration: builder.query({
      query: ({ uuid, parameters = 'all' }) => ({
        url: `/devices/${uuid}/configuration?parameters=${parameters}`,
        method: 'GET',
      }),
    }),
    updateDeviceConfiguration: builder.mutation({
      query: ({ uuid, deviceConfig = [] }) => ({
        url: `/devices/${uuid}/configuration`,
        method: 'PUT',
        body: { deviceConfig },
      }),
    }),
    triggerLmcManagerChargersAutoConf: builder.mutation({
      query: (lmcUuid) => ({
        url: `/devices/${lmcUuid}/lmcManagedChargersAutoConf`,
        method: 'POST',
      }),
    }),
    getLmcCommunications: builder.query<LmcChargerCommunicationStatus[], string>({
      query: (lmcUuid) => ({
        url: `/devices/${lmcUuid}/lmcChargersCommunicationStatus`,
        method: 'GET',
      }),
      transformResponse: (response: { data: LmcChargerCommunicationStatus[] }) => response.data,
    }),
  }),
});

export const {
  useCheckDeviceMutation,
  useAddDeviceMutation,
  useDeleteDeviceMutation,
  useUpdateDeviceMutation,
  useGetDeviceQuery,
  useGetDeviceStatusQuery,
  useGetDevicesQuery,
  useGetAllDevicesQuery,
  useStartChargingMutation,
  useStopChargingMutation,
  useGetDeviceTransactionsQuery,
  useGetDeviceTransactionsSummaryQuery,
  useLazyGetOcppVariableQuery,
  useSetOcppVariableMutation,
  useGetOcppVariablesQuery,
  useSetOcppVariablesMutation,
  useGetOcppVariablesDefQuery,
  useLazyGetLmcMultipointsQuery,
  useAddLmcMultipointMutation,
  useDeleteLmcMultipointMutation,
  useUpdateLmcMultipointMutation,
  useResetDeviceMutation,
  useUpdateDeviceFirmwareMutation,
  useGetDeviceConfigurationQuery,
  useUpdateDeviceConfigurationMutation,
  useTriggerLmcManagerChargersAutoConfMutation,
  useGetLmcCommunicationsQuery,
} = devicesApi;
