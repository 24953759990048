import { useEffect, useState } from 'react';

import { FeatureDto, FeatureId } from 'models/subscriptions';

import { useLazyGetCompanyPublicConnectorsCountQuery } from '@services/sites/endpoints';

export const useSubscriptionItemCount = (feature: FeatureDto) => {
  const [count, setCount] = useState<number>(0);
  const [getPublicConnectorsCount] = useLazyGetCompanyPublicConnectorsCountQuery();

  useEffect(() => {
    const loadCount = async () => {
      if (feature.featureId === FeatureId.DEVICE_PUBLIC_CHARGING) {
        const response = await getPublicConnectorsCount().unwrap();
        setCount(response);
      }
    };
    loadCount();
  }, [feature.featureId]);

  return { count };
};
