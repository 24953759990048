import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { LmcMultipoint } from 'models/lmc-multipoint';
import * as yup from 'yup';

import { Checkbox } from '@components/atoms/Checkbox';
import { Input } from '@components/atoms/Input';
import EditingSidebarBase from '@components/sidebars/EditingSidebarBase';
import { useForm } from '@hooks/useTypedForm';
import { useAddLmcMultipointMutation, useUpdateLmcMultipointMutation } from '@services/devices/endpoints';
import { selectDeviceByUuid, selectLmcMultipointsByLmcDeviceUuid } from '@services/devices/selectors';
import { useAppDispatch, useAppSelector } from '@services/hooks';
import { addToastMessage } from '@services/toastMessages';

const useAddSiteGroupSchema = ({ gridConnection = null }) => {
  const { t } = useTranslation();
  return yup.object().shape({
    name: yup
      .string()
      .required(t('nameIsRequired', 'Name is required'))
      .test('nameIsRequired', t('nameIsRequired', 'Name is required'), (value) => value?.trim?.() !== ''),
    currentLimit: yup
      .number()
      .typeError(t('mustBeNumber', 'Must be a number'))
      .required(t('required*', 'Required*'))
      .test('positive', t('mustBePositive', 'Must be positive'), (value) => !!value && value > 0)
      .test(
        'max',
        t('mustNotExceedValue', 'Must not exceed {{value}}', { value: ` ${gridConnection} A` }),
        (value) => (value && gridConnection && value <= gridConnection) || !gridConnection,
      ),
  });
};

export const AddSiteGroupSidebar = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const lmcDeviceUuid = searchParams.get('deviceUuid');
  const lmcMultipointUuid = searchParams.get('lmcMultipointUuid');
  const [isAddAnother, setIsAddAnother] = useState(false);

  const lmcDevice = useAppSelector((state) => (lmcDeviceUuid ? selectDeviceByUuid(state, lmcDeviceUuid) : undefined));
  const lmcMultipoints = useAppSelector((state) =>
    lmcDeviceUuid ? selectLmcMultipointsByLmcDeviceUuid(state, lmcDeviceUuid) : [],
  );

  const schema = useAddSiteGroupSchema(lmcDevice || {});
  const [lmcMultipointToEdit, setLmcMultipointToEdit] = useState<LmcMultipoint>();

  const { formState, register, handleSubmitAndResolve, reset, setValue } = useForm<LmcMultipoint>({
    schema,
  });

  const [addLmcMultipoint, { isLoading: isAddLoading, isError: isAddError }] = useAddLmcMultipointMutation();
  const [updateLmcMultipoint, { isLoading: isUpdateLoading, isError: isUpdateError }] =
    useUpdateLmcMultipointMutation();

  const saveValues = async ({ name, currentLimit }: LmcMultipoint) => {
    try {
      const mutation = lmcMultipointToEdit ? updateLmcMultipoint : addLmcMultipoint;
      if (!lmcDevice) {
        throw new Error(t('groupNotFound', 'Group not found'));
      }
      await mutation({
        name: name.trim(),
        currentLimit,
        lmcDeviceUuid: lmcDevice.uuid,
        multipointId: lmcMultipointToEdit ? lmcMultipointToEdit.multipointId : lmcMultipoints.length + 1,
        multipointUuid: lmcMultipointToEdit ? lmcMultipointToEdit.uuid : null,
      }).unwrap();
      reset();
      dispatch(
        addToastMessage({
          type: 'success',
          title: lmcMultipointToEdit ? t('groupUpdated', 'Group updated') : t('groupAdded', 'Group added'),
        }),
      );
      return !isAddAnother;
    } catch (error) {
      dispatch(
        addToastMessage({
          type: 'error',
          title: lmcMultipointToEdit
            ? t('updatingGroupFailed', 'Updating the group failed')
            : t('addingGroupFailed', 'Adding the group failed'),
          message: t('pleaseTryAgain', 'Please try again.'),
        }),
      );
      return false;
    }
  };

  useEffect(() => {
    if (lmcMultipointUuid) {
      const lmcMultipoint = lmcMultipoints.find((mp) => mp.uuid === lmcMultipointUuid);
      if (lmcMultipoint?.multipointId) {
        setValue('name', lmcMultipoint.name);
        setValue('currentLimit', lmcMultipoint.currentLimit);
        setLmcMultipointToEdit(lmcMultipoint);
      }
    }
  }, [lmcMultipointUuid, lmcMultipoints]);

  return (
    <EditingSidebarBase
      title={lmcMultipointToEdit ? t('editGroup', 'Edit group') : t('addGroup', 'Add a group')}
      saveLabel={lmcMultipointToEdit ? t('update', 'Update') : t('add', 'Add')}
      discardLabel={t('cancel', 'Cancel')}
      containerClassName="flex flex-col"
      containerBottomPadding="pb-36"
      onSaveValues={handleSubmitAndResolve(saveValues)}
      onDiscardValues={reset}
      anyDataChanged={formState.isDirty}
      updateFailed={isAddError || isUpdateError}
      updateLoading={isAddLoading || isUpdateLoading}
      footer={() =>
        !lmcMultipointToEdit && (
          <div className="py-4">
            <Checkbox
              name="addAnotherGroup"
              label={t('addAnotherGroupText', 'Add another group after this one')}
              checked={isAddAnother}
              onChange={() => setIsAddAnother(!isAddAnother)}
            />
          </div>
        )
      }
    >
      <div className="flex flex-col gap-y-4">
        <Input
          label={t('name', 'Name')}
          type="text"
          error={Boolean(formState.errors?.name)}
          helpText={formState.errors?.name?.message}
          {...register('name')}
        />
        <Input
          label={t('currentLimit', 'Current limit')}
          trailingText="A"
          type="number"
          isInteger
          error={Boolean(formState.errors?.currentLimit)}
          helpText={formState.errors?.currentLimit?.message}
          {...register('currentLimit')}
        />
      </div>
    </EditingSidebarBase>
  );
};
