import { Context, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Virtuoso } from 'react-virtuoso';

import { Box, IconButton, InputAdornment, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';

import { MenuContainer, MenuContainerProps } from '@components/atoms/MenuContainer';
import { StyledMenuItem, StyledMenuItemProps } from '@components/atoms/StyledMenuItem';
import { useFilterMenu } from '@components/filters/hooks/useFilterMenu';
import { ReactComponent as CloseIcon } from '@images/icons/Close.svg';
import { ReactComponent as ArrowLeftIcon } from '@images/icons/arrow_left.svg';
import { ReactComponent as SearchIcon } from '@images/icons/reporting/search.svg';

import { FilterMenuOption } from '.';
import { FiltersBaseContextType } from './context/FiltersContext';

type FilterMenuProps<ContextType, ExtractedType extends FilterMenuOption> = Omit<MenuContainerProps, 'children'> & {
  context: Context<ContextType & FiltersBaseContextType<ExtractedType>>;
  options?: StyledMenuItemProps[];
  selectedFilter?: FilterMenuOption;
  setSelectedFilter?: Dispatch<SetStateAction<FilterMenuOption | undefined>>;
};

export const FilterMenu = <ContextType, ExtractedType extends FilterMenuOption>({
  context,
  anchorEl,
  marginTop,
  horizontalOrigin,
  selectedFilter,
  setSelectedFilter,
  options,
  onClose,
}: FilterMenuProps<ContextType, ExtractedType>) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { filter, setFilter, filterOptionsMap } = useContext(context);
  const { filterLabel } = useFilterMenu(filterOptionsMap);
  const filterMenuOptions = (options?: StyledMenuItemProps[]) => {
    return options?.filter((option) => option.label?.toLowerCase().includes(filter.toLowerCase()));
  };

  const [filteredMenuOptions, setFilteredMenuOptions] = useState(filterMenuOptions(options));
  const filteredMenuOptionsLength = filteredMenuOptions?.length ?? 0;

  const clearFilter = () => {
    setFilter('');
    setSelectedFilter?.(undefined);
  };

  useEffect(() => {
    setFilteredMenuOptions(filterMenuOptions(options));
  }, [filter, options]);

  useEffect(() => {
    setFilter('');
  }, [selectedFilter]);

  return (
    <MenuContainer
      anchorEl={anchorEl}
      width="343px"
      marginTop={marginTop}
      horizontalOrigin={horizontalOrigin}
      onClose={() => {
        clearFilter();
        onClose();
      }}
      isMobile={isMobile}
      paperProps={{
        sx: {
          paddingBottom: isMobile ? 0 : 0.5,
        },
      }}
    >
      {isMobile && (
        <>
          <IconButton
            onClick={() => {
              clearFilter();
              if (!selectedFilter || !setSelectedFilter) {
                onClose();
              }
            }}
            sx={{
              position: 'absolute',
              top: 16,
              right: 16,
              color: 'black',
            }}
          >
            {selectedFilter ? <ArrowLeftIcon /> : <CloseIcon />}
          </IconButton>
          <Typography variant="h3" py={3} px={2}>
            {t('filter', 'Filter')}
          </Typography>
        </>
      )}

      <TextField
        name="vool-filter"
        autoComplete="off"
        placeholder={selectedFilter ? filterLabel[selectedFilter] : t('filter', 'Filter')}
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        fullWidth
        variant="standard"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={{ color: '#282828', cursor: 'default' }}>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        sx={{
          '& .MuiInput-root': {
            '&:hover:not(.Mui-disabled, .Mui-error):before, ::before, ::after': { border: 'none' },
            '&.Mui-disabled:before': { borderBottomStyle: 'none' },
          },
          padding: '8px 12px',
          ...(isMobile && { position: 'absolute', bottom: 0, borderTop: '1px solid #F4F4F4', paddingTop: '4px' }),
        }}
      />
      <Box
        display="flex"
        flexDirection="column"
        px={0.5}
        height="calc(100% - 130px)"
        sx={{
          ...(!isMobile && { ':not(:empty)': { borderTop: '1px solid #F4F4F4', paddingTop: '4px' } }),
        }}
      >
        {filteredMenuOptionsLength < 20 ? (
          filteredMenuOptions?.map((option) => (
            <StyledMenuItem
              key={option.uuid}
              {...option}
              sx={{
                ...(isMobile && { height: '56px', width: '100%' }),
              }}
            />
          ))
        ) : (
          <Virtuoso
            style={{ height: isMobile ? '100%' : '600px' }}
            totalCount={filteredMenuOptionsLength}
            itemContent={(index) => (
              <StyledMenuItem
                key={filteredMenuOptions?.[index].uuid}
                {...filteredMenuOptions?.[index]}
                sx={{
                  ...(isMobile && { height: '56px', width: '100%' }),
                }}
              />
            )}
          />
        )}
      </Box>
    </MenuContainer>
  );
};
