import { useEffect, useState } from 'react';

import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';

import { LocationMarkerIcon } from '@heroicons/react/solid';

import { Card } from '@components/atoms/Card';
import { googleApiKey } from '@config/consts';
import { ReactComponent as MapIcon } from '@images/icons/nav/Map.svg';

import mapStyles from './mapStyles';

const DEFAULT_CENTER = {
  lat: 59.43696079999999,
  lng: 24.7535746,
};
const DEFAULT_ZOOM = 11;

const Map = ({ location, setFormValue }) => {
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);

  useEffect(() => {
    setZoom(location ? 18 : DEFAULT_ZOOM);
  }, [location]);

  return (
    <>
      {location ? (
        <Card className="relative h-56 w-full rounded-lg" full>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: googleApiKey,
              libraries: ['places'],
              version: 'weekly',
              id: '__googleMapsScriptId',
            }}
            defaultCenter={DEFAULT_CENTER}
            defaultZoom={DEFAULT_ZOOM}
            zoom={zoom}
            options={{
              styles: mapStyles,
              gestureHandling: 'greedy',
            }}
            onDragEnd={async ({ center }) => {
              const lat = center.lat();
              const lng = center.lng();
              setFormValue({ lat, lng });
            }}
            {...(location && {
              center: {
                lat: location.lat,
                lng: location.lng,
              },
            })}
          />
          <LocationMarkerIcon className="absolute inset-x-2/4 inset-y-1/2 z-10 h-5 w-5 -translate-x-1/2 -translate-y-1/2 text-red-500" />
        </Card>
      ) : (
        <div className="flex h-56 w-full items-center justify-center rounded-lg bg-gray-100">
          <MapIcon className="scale-[1.75] [&_path]:fill-gray-200" />
        </div>
      )}
      <div className={`${location ? '' : 'invisible'} mt-1 text-right text-xs text-gray-700 dark:text-warmgray-200`}>
        * Drag map to specify location
      </div>
    </>
  );
};

Map.propTypes = {
  location: PropTypes.object,
  setFormValue: PropTypes.func,
};

Map.defaultProps = {
  location: null,
  setFormValue: () => {},
};

export default Map;
