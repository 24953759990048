import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { trimEnd } from 'lodash';
import { Device } from 'models/device';
import { DeviceModalOption, DeviceStatus, OcppVersion } from 'models/device.enums';
import { Permission } from 'models/permission.enum';

import { MenuItem } from '@components/dropdown/MenuComponent';
import { selectPermissionByName, selectUser } from '@services/auth/selectors';
import { useAppSelector } from '@services/hooks';
import { isVoolDevice } from '@views/Sites/SiteDevicesAndGroups/siteDevicesAndGroupsHelpers/siteDevicesUtils';

export enum UsageLocation {
  SINGLE_DEVICE = 'singleDevice',
  SITE_DEVICES = 'siteDevices',
  BACKOFFICE_DEVICES = 'backofficeDevices',
}

type GetDeviceMenuItemsFunction = (device: Device) => MenuItem[];

type UseGetDeviceMenuItemsProps = {
  onModalOptionSelected: Dispatch<SetStateAction<DeviceModalOption | undefined>>;
  onDeviceSelected?: (device: Device) => void;
  openEditModal?: () => void;
  usageLocation?: UsageLocation;
};

export const useGetDeviceMenuItems = ({
  onModalOptionSelected,
  onDeviceSelected,
  openEditModal,
  usageLocation,
}: UseGetDeviceMenuItemsProps): GetDeviceMenuItemsFunction => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const trimmedPathname = trimEnd(pathname, '/');
  const canEditDevices = useAppSelector((state) => selectPermissionByName(state, Permission.CAN_EDIT_DEVICES));
  const canDeleteDevices = useAppSelector((state) => selectPermissionByName(state, Permission.CAN_DELETE_DEVICES));
  const canRestartDevices = useAppSelector((state) => selectPermissionByName(state, Permission.CAN_RESTART_DEVICES));
  const canUpdateDeviceFirmware = useAppSelector((state) =>
    selectPermissionByName(state, Permission.CAN_UPDATE_DEVICE_FIRMWARE),
  );
  const canSeeDeviceOcppVariables = useAppSelector((state) =>
    selectPermissionByName(state, Permission.CAN_SEE_DEVICE_OCPP_VARIABLES),
  );
  const isAdmin = !!useAppSelector(selectUser)?.admin;

  const getDeviceMenuItems: GetDeviceMenuItemsFunction = (device) => [
    {
      title: t('configuration', 'Configuration'),
      key: 'configuration',
      onClick: () =>
        navigate(`${trimmedPathname}/details/${usageLocation !== UsageLocation.SINGLE_DEVICE ? device.uuid : ''}`),
      hidden: usageLocation === UsageLocation.SINGLE_DEVICE,
    },
    {
      title: t('goToDevice', 'Go to device'),
      key: 'goToDevice',
      onClick: () => navigate(`/devices/${device.uuid}`),
      hidden: usageLocation !== UsageLocation.BACKOFFICE_DEVICES,
    },
    {
      title: t('viewLogs', 'View logs'),
      key: 'viewLogs',
      onClick: () => navigate(`/logs/?deviceUuid=${device.uuid}`),
      hidden: usageLocation !== UsageLocation.BACKOFFICE_DEVICES,
    },
    {
      title: t('editDevice', 'Edit device'),
      key: 'edit-device',
      onClick: () => {
        onDeviceSelected?.(device);
        openEditModal?.();
        onModalOptionSelected(DeviceModalOption.EDIT);
      },
      hidden: usageLocation === UsageLocation.BACKOFFICE_DEVICES || !canEditDevices,
    },
    {
      title: t('ocppSetup', 'OCPP setup'),
      key: 'ocpp-setup',
      onClick: () => {
        onDeviceSelected?.(device);
        onModalOptionSelected(DeviceModalOption.OCPP_SETUP);
      },
      hidden: !device.ocppPassword || usageLocation === UsageLocation.BACKOFFICE_DEVICES,
    },
    {
      title: t('ocppVariables', 'OCPP variables'),
      key: 'ocpp-variables',
      onClick: () => {
        if (device.ocppVersion === OcppVersion.V1_6) {
          navigate(
            `${trimmedPathname}/ocpp-variables/${usageLocation !== UsageLocation.SINGLE_DEVICE ? device.uuid : ''}`,
          );
        } else {
          onDeviceSelected?.(device);
          onModalOptionSelected(DeviceModalOption.OCPP_VARIABLES);
        }
      },
      disabled: device.status !== DeviceStatus.ONLINE,
      hidden: usageLocation === UsageLocation.BACKOFFICE_DEVICES || !canSeeDeviceOcppVariables,
    },
    {
      title: t('updateDevice', 'Update device'),
      key: 'update-device',
      onClick: () => {
        onDeviceSelected?.(device);
        onModalOptionSelected(DeviceModalOption.OCPP_UPDATE);
      },
      disabled: device.status !== DeviceStatus.ONLINE,
      hidden: !canUpdateDeviceFirmware || !isVoolDevice(device),
    },
    {
      title: t('restartDevice', 'Restart device'),
      key: 'ocpp-reset',
      onClick: () => {
        onDeviceSelected?.(device);
        onModalOptionSelected(DeviceModalOption.OCPP_RESET);
      },
      disabled: device.status !== DeviceStatus.ONLINE,
      hidden: !canRestartDevices,
    },
    {
      title: t('datadogLogs', 'DATADOG logs'),
      key: 'datadog-logs',
      onClick: () =>
        window.open(
          `https://app.datadoghq.eu/logs?query=source%3A%28ocpp-data-transfer-worker%20OR%20pusher-worker%20OR%20device-status-change-worker%29%20%40meta.fieldValue%3A${device.serialNumber}&agg_m=count&agg_q=%40pid&agg_t=count&index=&integration_id=redis&integration_short_name=pid_overview&saved_view=&step=30000&top_n=50&viz=stream&live=true`,
        ),
      hidden: !isAdmin || !device.serialNumber || usageLocation === UsageLocation.BACKOFFICE_DEVICES,
    },
    {
      title: t('deleteDevice', 'Delete device'),
      key: 'delete-device',
      className: 'text-vermillion',
      onClick: () => {
        onDeviceSelected?.(device);
        onModalOptionSelected(DeviceModalOption.DELETE);
      },
      hidden: usageLocation === UsageLocation.BACKOFFICE_DEVICES || !canDeleteDevices,
    },
  ];

  return getDeviceMenuItems;
};
