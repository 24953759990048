import { Context, ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';

import { CardContainer } from '@components/atoms/CardContainer';
import { FilterMenuOption } from '@components/filters';
import { FiltersBaseContextType } from '@components/filters/context/FiltersContext';
import useActiveCurrency from '@hooks/useActiveCurrency';
import { Order } from '@services/reporting/endpoints';

import { NoResultsCard } from '../../App/views/reporting/table/NoResultsCard';
import { StyledTableRow } from './StyledTableRow';

export const useMaterialTableSorting = <ContextDataType,>(
  initialOrderBy: keyof ContextDataType,
  initialOrder?: Order,
) => {
  const [order, setOrder] = useState<Order>(initialOrder ?? 'desc');
  const [orderBy, setOrderBy] = useState<keyof ContextDataType>(initialOrderBy);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ContextDataType) => {
    if (property !== orderBy) {
      setOrderBy(property);
    } else {
      setOrder(order === 'asc' ? 'desc' : 'asc');
    }
  };

  return {
    order,
    orderBy,
    setOrder,
    setOrderBy,
    handleRequestSort,
  };
};

export const compareStringValues = (a: string | undefined, b: string | undefined, order: 'asc' | 'desc'): number => {
  const aLower = a?.toLowerCase();
  const bLower = b?.toLowerCase();
  if (aLower === bLower) {
    return 0;
  }
  if (order === 'asc') {
    const comparison = (aLower ?? '\uffff') < (bLower ?? '\uffff') ? -1 : 1;
    return comparison;
  } else {
    const comparison = (aLower ?? '') > (bLower ?? '') ? -1 : 1;
    return comparison;
  }
};

export const compareValues = (
  a: number | string | Date | undefined,
  b: number | string | Date | undefined,
  order: 'asc' | 'desc',
): number => {
  const valueOfA = a ?? Infinity;
  const valueOfB = b ?? Infinity;

  if (valueOfA === valueOfB) return 0;
  const comparison = valueOfA > valueOfB ? 1 : -1;
  return order === 'asc' ? comparison : -comparison;
};

export type ColumnData<ContextDataType> = {
  dataKey: keyof ContextDataType | 'menu';
  label: ReactNode;
  unit?: string;
  subtitle?: string;
  numeric?: boolean;
  currency?: boolean;
  width?: number;
  sortable?: boolean;
  tooltip?: string;
  hidden?: boolean;
};

const TableHeaderDefaultContent = <ContextDataType,>({ column }: { column: ColumnData<ContextDataType> }) => {
  const currency = useActiveCurrency();

  return (
    <Tooltip title={column.tooltip && <Typography variant="p12b">{column.tooltip}</Typography>}>
      <Box display="flex" flexDirection="column">
        {column.currency && (
          <Typography variant="p12" color="#999898">
            {currency.symbol}
          </Typography>
        )}
        {column.unit && (
          <Typography variant="p12" color="#999898">
            {column.unit}
          </Typography>
        )}
        <Typography variant="p14" color="#5E5E5E">
          {column.label}
        </Typography>
        {column.subtitle && (
          <Typography variant="p14" color="#5E5E5E">
            {column.subtitle}
          </Typography>
        )}
      </Box>
    </Tooltip>
  );
};

type EnhancedTableProps<ContextDataType> = {
  columns: ColumnData<ContextDataType>[];
  orderBy: keyof ContextDataType;
  order?: Order;
  onRequestSort?: (event: React.MouseEvent<unknown>, property: keyof ContextDataType) => void;
  TableHeaderContent?: ({ column }: { column: ColumnData<ContextDataType> }) => JSX.Element;
};

const fixedSortableHeaderContent = <ContextDataType,>(props: EnhancedTableProps<ContextDataType>) => {
  const { columns, TableHeaderContent, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof ContextDataType) => (event: React.MouseEvent<unknown>) => {
    onRequestSort?.(event, property);
  };
  const TableHeaderContentComponent = TableHeaderContent ?? TableHeaderDefaultContent;
  return (
    <TableRow
      sx={{
        '& > *': {
          borderBottom: '1px solid #F4F4F4 !important',
        },
      }}
    >
      {columns
        .filter((column) => !column.hidden)
        .map((column) => (
          <TableCell
            key={String(column.dataKey)}
            variant="head"
            align={column.numeric ? 'right' : 'left'}
            sortDirection={orderBy === column.dataKey ? order : false}
            sx={{
              width: column.width,
              backgroundColor: 'background.paper',
            }}
          >
            {column.sortable ? (
              <TableSortLabel
                active={orderBy === column.dataKey}
                direction={orderBy === column.dataKey ? order : 'desc'}
                onClick={column.dataKey === 'menu' ? undefined : createSortHandler(column.dataKey)}
              >
                <TableHeaderContentComponent column={column} />
              </TableSortLabel>
            ) : (
              <TableHeaderContentComponent column={column} />
            )}
          </TableCell>
        ))}
    </TableRow>
  );
};

type MaterialTableProps<ContextDataType, ContextType, ExtractedType extends FilterMenuOption> = {
  headerProps: EnhancedTableProps<ContextDataType>;
  loaders: {
    TableLoader: () => JSX.Element;
    RowLoader: () => JSX.Element;
  };
  data?: ContextDataType[];
  context?: Context<ContextType & FiltersBaseContextType<ExtractedType>>;
  isLoading?: boolean;
  sortingStorageKey?: string;
  renderRows: (rows: ContextDataType[], columns: ColumnData<ContextDataType>[]) => JSX.Element[];
  loadMore?: () => void;
};

export const MaterialTable = <ContextDataType, ContextType, ExtractedType extends FilterMenuOption>({
  headerProps,
  loaders,
  context,
  data,
  isLoading,
  sortingStorageKey,
  renderRows,
  loadMore,
}: MaterialTableProps<ContextDataType, ContextType, ExtractedType>) => {
  const { t } = useTranslation();
  const { ref, inView } = useInView({ rootMargin: '300px 0px' });
  const { TableLoader, RowLoader } = loaders;
  const { order, orderBy, handleRequestSort } = useMaterialTableSorting<ContextDataType>(
    (localStorage.getItem(`${sortingStorageKey}SortingParam`) as keyof ContextDataType | null) ?? headerProps.orderBy,
    (localStorage.getItem(`${sortingStorageKey}SortingOrder`) as Order | null) ?? headerProps.order,
  );

  const sortedData = useMemo(() => {
    if (headerProps.onRequestSort) {
      return data ?? [];
    }
    const result = [...(data ?? [])];
    return result.sort((a, b) => compareStringValues(String(a[orderBy]), String(b[orderBy]), order));
  }, [data, order, orderBy]);

  useEffect(() => {
    if (sortingStorageKey) {
      localStorage.setItem(`${sortingStorageKey}SortingParam`, String(orderBy));
      localStorage.setItem(`${sortingStorageKey}SortingOrder`, String(order));
    }
  }, [orderBy, order]);

  useEffect(() => {
    if (inView && !isLoading) {
      loadMore?.();
    }
  }, [inView]);

  if ((data?.length === 0 || !loadMore) && isLoading) {
    return <TableLoader />;
  }

  if (data?.length === 0 && !isLoading) {
    return context ? <NoResultsCard hideFilterReset={!loadMore} context={context} /> : null;
  }

  return (
    <TableContainer component={CardContainer} sx={{ p: 0, width: '100%' }}>
      <Table stickyHeader style={{ borderCollapse: 'collapse' }}>
        <TableHead>
          {fixedSortableHeaderContent({
            ...headerProps,
            ...(!headerProps.onRequestSort && {
              order,
              orderBy,
              onRequestSort: handleRequestSort,
            }),
          })}
        </TableHead>
        <TableBody>
          {renderRows(
            sortedData,
            headerProps.columns.filter((column) => !column.hidden),
          )}
          {isLoading ? (
            <RowLoader />
          ) : (
            loadMore && (
              <StyledTableRow>
                <TableCell colSpan={headerProps.columns.length}>
                  <Typography variant="p14" display="flex" justifyContent="center">
                    {t('noMoreResults', 'No more results')}
                  </Typography>
                </TableCell>
              </StyledTableRow>
            )
          )}
        </TableBody>
      </Table>
      <div ref={ref} />
    </TableContainer>
  );
};
