import { useTranslation } from 'react-i18next';

import { FeatureDto } from 'models/subscriptions';

import { Box, Typography, useTheme } from '@mui/material';

import { BulletList } from '@components/atoms/BulletList';

type PublicChargingPricingProps = {
  feature: FeatureDto;
};

export const PublicChargingPricing = ({ feature }: PublicChargingPricingProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="column" color={theme.palette.darkGray}>
      <Typography variant="p14b">
        {t('publicCharging.pleaseProvide', 'Before public charging can be enabled, please provide:')}
      </Typography>
      <BulletList>
        <Typography variant="p14">{t('yourCompanyDetails', 'Your company details')}</Typography>
        <Typography variant="p14">{t('aPayoutMethod', 'A payout method')}</Typography>
        <Typography variant="p14">{t('aPaymentMethod', 'A payment method')}</Typography>
      </BulletList>
      <Typography variant="p14">
        {t('publicCharging.onceTheseAreAdded', 'Once these are added, your connectors can be made publicly available.')}
      </Typography>
      <Box>&nbsp;</Box>
      <Typography variant="p14b">{t('generalPricingInfo', 'General pricing information')}</Typography>
      <BulletList>
        <Typography variant="p14">
          {t('publicCharging.revenueShare', '{{percentage}}% of the revenue goes to VOOL', { percentage: 4 })}
        </Typography>
        <Typography variant="p14">
          {t('pricePerConnector', '{{price}}€ per connector', {
            price: Number(feature.featurePrice.price).toFixed(2),
          })}
        </Typography>
      </BulletList>
      <Typography variant="p14">{t('voolWillInvoiceYouOnceAMonth', 'VOOL will invoice you once a month.')}</Typography>
    </Box>
  );
};
