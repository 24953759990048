import { Context, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

import { MenuContainer } from '@components/atoms/MenuContainer';
import { OutlinedButton } from '@components/atoms/OutlinedButton';
import { StyledMenuItem, StyledMenuItemProps } from '@components/atoms/StyledMenuItem';
import { FilterMenu } from '@components/filters/FilterMenu';

import { FilterMenuOption } from '.';
import { FiltersBaseContextType } from './context/FiltersContext';
import { useFilterMenu } from './hooks/useFilterMenu';

type ActiveFilterProps<ContextType, ExtractedType extends FilterMenuOption> = {
  context: Context<ContextType & FiltersBaseContextType<ExtractedType>>;
  filter: FilterMenuOption;
  options: StyledMenuItemProps[];
};

export const ActiveFilter = <ContextType, ExtractedType extends FilterMenuOption>({
  context,
  filter,
  options,
}: ActiveFilterProps<ContextType, ExtractedType>) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { optionChecked, reset, filterInclusivityMap, setFilterInclusive, filterOptionsMap } = useContext(context);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const [inclusivityAnchorEl, setInclusivityAnchorEl] = useState<HTMLElement>();
  const { filterPluralLabels, allFilterMenuOptions } = useFilterMenu(filterOptionsMap);

  const checkedOptionsCount = useMemo(() => options.filter(optionChecked).length, [options]);
  const isSingleOption = checkedOptionsCount === 1;
  const inclusive = filterInclusivityMap[filter as keyof typeof filterInclusivityMap];
  const FilterIcon = allFilterMenuOptions[filter].Icon;

  return (
    <OutlinedButton
      gap={1}
      sx={{
        ...(isMobile && {
          height: 'auto',
          width: 1,
        }),
      }}
    >
      {FilterIcon && <FilterIcon />}
      <Typography variant="p14" textTransform={filter === 'rfid' ? 'uppercase' : 'capitalize'}>
        {filter}
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        gap={1}
        onClick={(e) => setInclusivityAnchorEl(e.currentTarget)}
        sx={{ cursor: 'pointer', ':hover': { backgroundColor: '#F4F4F4' } }}
      >
        <Typography variant="p14" color="#5E5E5E">
          {isSingleOption && inclusive && t('is', 'is')}
          {!isSingleOption && inclusive && t('isAnyOf', 'is any of')}
          {!inclusive && t('isNot', 'is not')}
        </Typography>
        <ArrowForwardIcon
          sx={{
            fontSize: '16px',
            color: '#999898',
          }}
        />
      </Box>
      <MenuContainer
        anchorEl={inclusivityAnchorEl}
        marginTop="8px"
        horizontalOrigin="left"
        onClose={() => setInclusivityAnchorEl(undefined)}
      >
        <Box display="flex" flexDirection="column" justifyContent="center" px={0.5}>
          <StyledMenuItem
            width="auto"
            Icon={CheckIcon}
            label={isSingleOption ? t('is', 'is') : t('isAnyOf', 'is any of')}
            onClick={() => {
              setFilterInclusive(filter, true);
              setInclusivityAnchorEl(undefined);
            }}
          />
          <StyledMenuItem
            width="auto"
            Icon={CloseIcon}
            label={t('isNot', 'is not')}
            onClick={() => {
              setFilterInclusive(filter, false);
              setInclusivityAnchorEl(undefined);
            }}
          />
        </Box>
      </MenuContainer>
      <Typography
        variant="p14"
        textTransform={isSingleOption || filter === 'rfid' ? 'none' : 'lowercase'}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        noWrap
        sx={{
          cursor: 'pointer',
          ':hover': { backgroundColor: '#F4F4F4' },
        }}
      >
        {isSingleOption ? options.find(optionChecked)?.label : `${checkedOptionsCount} ${filterPluralLabels[filter]}`}
      </Typography>
      <CloseIcon
        sx={{
          fontSize: '16px',
          cursor: 'pointer',
        }}
        onClick={() => reset(filter)}
      />
      <FilterMenu
        context={context}
        anchorEl={anchorEl}
        marginTop="8px"
        horizontalOrigin="left"
        options={options}
        selectedFilter={filter}
        onClose={() => setAnchorEl(undefined)}
      />
    </OutlinedButton>
  );
};
