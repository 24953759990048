import { Checkbox, CheckboxProps, styled } from '@mui/material';

import { ReactComponent as CheckboxCheckedIcon } from '@images/icons/atoms/checkbox-checked.svg';
import { ReactComponent as CheckboxIcon } from '@images/icons/atoms/checkbox.svg';

const BaseStyledCheckbox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.lightGray,
  '&.MuiCheckbox-root': {
    padding: '0',
    '&:hover': {
      backgroundColor: theme.palette.pureWhite,
      svg: {
        rect: {
          stroke: theme.palette.spanishGray,
        },
      },
    },
    '&.Mui-checked': {
      svg: {
        rect: {
          stroke: theme.palette.forestGreenCrayola,
        },
      },
      '&:hover': {
        svg: {
          rect: {
            stroke: '#1F4C3C',
            fill: '#1F4C3C',
          },
        },
      },
    },
    '&.Mui-disabled': {
      svg: {
        rect: {
          fill: theme.palette.cultured,
        },
      },
    },
  },
}));

export const StyledCheckbox = (props?: CheckboxProps) => {
  return <BaseStyledCheckbox icon={<CheckboxIcon />} checkedIcon={<CheckboxCheckedIcon />} {...props} />;
};
