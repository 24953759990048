import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { DeviceModalOption } from 'models/device.enums';
import { Permission } from 'models/permission.enum';

import { Box, Skeleton, TableCell, Typography } from '@mui/material';

import { ColumnData, MaterialTable } from '@components/atoms/MaterialTable';
import { StyledMenuItemProps } from '@components/atoms/StyledMenuItem';
import { StyledTableRow } from '@components/atoms/StyledTableRow';
import { selectPermissionByName } from '@services/auth/selectors';
import { useAppSelector } from '@services/hooks';
import { MenuCell } from '@views/reporting/table/MenuCell';

import { DevicesAndGroupsContext } from '../DevicesAndGroupsProvider';
import { InverterWithStats } from '../devicesAndGroupsTypes';
import { DeviceIcon } from '../siteDevicesAndGroupsHelpers/DeviceIcon';
import { InverterStatusContent } from '../siteDevicesAndGroupsHelpers/InverterStatusContent';
import { SiteInvertersTableRowSkeleton, SiteInvertersTableSkeleton } from './SiteInvertersTableSkeleton';

type SiteInvertersColumnData = ColumnData<InverterWithStats>;

const useColumns = (hiddenColumns?: (keyof InverterWithStats | 'menu')[]): SiteInvertersColumnData[] => {
  const { t } = useTranslation();
  const columns: SiteInvertersColumnData[] = [
    {
      width: 360,
      label: t('name', 'Name'),
      dataKey: 'name',
      sortable: true,
    },
    {
      width: 160,
      label: t('status', 'Status'),
      dataKey: 'status',
      sortable: true,
    },
    {
      width: 220,
      label: t('currentRateKw', 'Current rate kW'),
      dataKey: 'currentRateKw',
      sortable: true,
      numeric: true,
    },
    {
      width: 220,
      label: t('energyProducedKwh', 'Energy produced kWh'),
      dataKey: 'energyProducedKwh',
      sortable: true,
      numeric: true,
    },
    {
      width: 20,
      label: '',
      dataKey: 'menu',
      sortable: false,
    },
  ];
  return hiddenColumns?.length ? columns.filter((column) => !hiddenColumns.includes(column.dataKey)) : columns;
};

type RenderRowsProps = {
  rows: InverterWithStats[];
  columns: SiteInvertersColumnData[];
  selectedInverter?: InverterWithStats;
  isFetchingSummary?: boolean;
  onModalOptionSelected: (option: DeviceModalOption) => void;
  onInverterSelected: (inverter: InverterWithStats) => void;
};

const renderRows = ({ rows, ...rest }: RenderRowsProps) =>
  rows.map((row) => <TableRow key={row.uuid} row={row} {...rest} />);

type TableRowProps = Omit<RenderRowsProps, 'rows'> & {
  row: InverterWithStats;
};

const TableRow = ({
  row,
  columns,
  selectedInverter,
  isFetchingSummary,
  onModalOptionSelected,
  onInverterSelected,
}: TableRowProps) => {
  const { t } = useTranslation();

  const canEditDevices = useAppSelector((state) => selectPermissionByName(state, Permission.CAN_EDIT_DEVICES));
  const canDeleteDevices = useAppSelector((state) => selectPermissionByName(state, Permission.CAN_DELETE_DEVICES));
  const getInverterMenuOptions = (inverter: InverterWithStats): StyledMenuItemProps[] => [
    {
      title: t('renameSolarInverter', 'Rename solar inverter'),
      key: 'renameSolarInverter',
      onClick: () => {
        onInverterSelected(inverter);
        onModalOptionSelected(DeviceModalOption.RENAME);
      },
      hidden: !canEditDevices,
    },
    {
      title: t('deleteDevice', 'Delete device'),
      className: canDeleteDevices ? 'text-vermillion' : '',
      key: 'deleteDevice',
      onClick: () => {
        onInverterSelected(inverter);
        onModalOptionSelected(DeviceModalOption.DELETE);
      },
      hidden: !canDeleteDevices,
    },
  ];

  return (
    <StyledTableRow
      key={row.uuid}
      sx={{
        bgcolor: row.uuid === selectedInverter?.uuid ? '#F4F4F4' : 'background.paper',
      }}
    >
      {columns.map((column) => {
        switch (column.dataKey) {
          case 'name':
            return (
              <TableCell key={column.dataKey} align={column.numeric ? 'right' : 'left'} width={column.width}>
                <Link to={`../inverters/${row.uuid}`} className="flex w-full text-left lg:min-w-[200px]">
                  <DeviceIcon device={row} />
                  <Box display="flex" flexDirection="column" justifyContent="center" pl={{ lg: 4 }}>
                    <Typography variant="p16b">{row.name}</Typography>
                    <Typography variant="p14">{row.brand} · Inverter</Typography>
                  </Box>
                </Link>
              </TableCell>
            );
          case 'status':
            return (
              <TableCell key={column.dataKey} align={column.numeric ? 'right' : 'left'} width={column.width}>
                <InverterStatusContent isProducing={!!row.isProducing} />
              </TableCell>
            );
          case 'currentRateKw': {
            return (
              <TableCell key={column.dataKey} align={column.numeric ? 'right' : 'left'} width={column.width}>
                {row.isProducing && row.currentRateKw ? row.currentRateKw.toFixed(2) : '-'}
              </TableCell>
            );
          }
          case 'energyProducedKwh': {
            const energyProducedKwhValue =
              !isFetchingSummary && row.energyProducedKwh ? row.energyProducedKwh.toFixed(2) : '-';
            return (
              <TableCell key={column.dataKey} align={column.numeric ? 'right' : 'left'} width={column.width}>
                {isFetchingSummary ? (
                  <Box display="flex" alignItems="center" justifyContent="end">
                    <Skeleton variant="text" animation="wave" width="30%" />
                  </Box>
                ) : (
                  energyProducedKwhValue
                )}
              </TableCell>
            );
          }
          case 'menu':
            return (
              <MenuCell
                key={column.dataKey}
                align="right"
                width={column.width}
                menuOptions={getInverterMenuOptions(row)}
              />
            );
          default:
            return (
              <TableCell key={column.dataKey} align={column.numeric ? 'right' : 'left'} width={column.width}>
                <Typography variant="p14">{String(row[column.dataKey])}</Typography>
              </TableCell>
            );
        }
      })}
    </StyledTableRow>
  );
};

type SiteInvertersMaterialTableProps = {
  selectedDevice?: InverterWithStats;
  onDeviceSelected: (inverter: InverterWithStats) => void;
  onModalOptionSelected: (option: DeviceModalOption) => void;
};

export const SiteInvertersMaterialTable = ({
  selectedDevice,
  onDeviceSelected,
  onModalOptionSelected,
}: SiteInvertersMaterialTableProps) => {
  const { siteUuid, invertersWithStats, dataLoading, isFetchingGetSiteInvertersSummary, order, orderBy } =
    useContext(DevicesAndGroupsContext);
  const hiddenColumns: (keyof InverterWithStats | 'menu')[] = !siteUuid ? ['energyProducedKwh'] : [];
  const columns = useColumns(hiddenColumns);

  return (
    <MaterialTable
      sortingStorageKey="siteInvertersTable"
      data={invertersWithStats}
      isLoading={dataLoading}
      headerProps={{
        columns,
        order,
        orderBy,
      }}
      renderRows={(rows, columns) =>
        renderRows({
          rows,
          columns,
          selectedInverter: selectedDevice,
          isFetchingSummary: isFetchingGetSiteInvertersSummary,
          onModalOptionSelected,
          onInverterSelected: onDeviceSelected,
        })
      }
      loaders={{
        TableLoader: SiteInvertersTableSkeleton,
        RowLoader: SiteInvertersTableRowSkeleton,
      }}
    />
  );
};
