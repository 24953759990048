export enum FeatureId {
  COMPANY_REPORT_SCHEDULES = 'company.report-schedules',
  DEVICE_PUBLIC_CHARGING = 'device.public-charging',
  DEVICE_THIRD_PARTY_CHARGERS = 'device.third-party-chargers',
}

export type FeatureDto = {
  uuid: string;
  featureId: FeatureId;
  name: string;
  description?: string;
  trialPeriodDays?: number;
  featurePrice: FeaturePriceDto;
  featureDiscount?: FeatureDiscountDto;
  subscription?: SubscriptionDto;
};

export type FeaturePriceDto = {
  uuid: string;
  featureUuid: string;
  price: number;
  pricingModel: PricingModel;
  tierInterval?: number;
  effectiveDate: Date;
};

/**
 * Enum representing different pricing models.
 */
export enum PricingModel {
  /**
   * Pricing model based on individual units or items.
   */
  PER_UNIT = 'per-unit',

  /**
   * Pricing model involving tiers or thresholds.
   */
  TIERED = 'tiered',

  /**
   * Recurring flat rate pricing model.
   */
  FLAT = 'flat',
}

export type FeatureDiscountDto = {
  uuid: string;
  featureUuid: string;
  discountPercentage: number;
  companyDiscount?: CompanyDiscountDto;
};

export type CompanyDiscountDto = {
  uuid: string;
  companyUuid: string;
  featureDiscountUuid: string;
  validFrom: Date;
  validTo?: Date;
  company: CompanyDto;
};

export type CompanyDto = {
  uuid: string;
  name: string;
};

export type SubscriptionDto = {
  uuid: string;
  companyUuid: string;
  featureUuid: string;
  isActive: boolean;
  activatedAt: Date;
  deactivatedAt?: Date;
  trialEndsAt?: Date;
};
