import { DeviceType } from 'models/device.enums';

import { createAppSelector } from '@services/hooks';
import { RootState } from '@services/store';

export const deviceSlice = (state: RootState) => state.devices;

export const selectDevices = createAppSelector(deviceSlice, (slice) => slice.list);

export const selectDevicesBySiteUuid = createAppSelector(
  [selectDevices, (state, siteUuid: string | undefined) => siteUuid],
  (devices, siteUuid) => (siteUuid ? devices.filter((d) => d.siteUuid === siteUuid) : []),
);
export const makeSelectChargersBySiteUuid = () =>
  createAppSelector(selectDevicesBySiteUuid, (siteDevices) => siteDevices.filter((d) => d.type === DeviceType.CHARGER));
export const selectChargersBySiteUuid = makeSelectChargersBySiteUuid();
export const selectLmcsBySiteUuid = createAppSelector(selectDevicesBySiteUuid, (siteDevices) =>
  siteDevices.filter((d) => d.type === DeviceType.LMC),
);
export const selectDevicesBy = createAppSelector(
  selectDevices,
  (state: RootState, type?: DeviceType) => type,
  (state: RootState, type?: DeviceType, siteUuid?: string) => siteUuid,
  (state: RootState, type?: DeviceType, siteUuid?: string, companyUuid?: string) => companyUuid,
  (devices, type, siteUuid, companyUuid) =>
    devices.filter(
      (d) =>
        (!siteUuid || d.siteUuid === siteUuid) &&
        (!companyUuid || d.companyUuid === companyUuid) &&
        (!type || d.type === type),
    ),
);
export const selectDeviceTransactions = createAppSelector(deviceSlice, (slice) => slice.deviceTransactions);
export const selectDeviceByUuid = createAppSelector(
  selectDevices,
  (state: RootState, uuid: string) => uuid,
  (devices, uuid) => devices.find((device) => device.uuid === uuid),
);
export const selectDeviceTransactionsSummary = createAppSelector(
  deviceSlice,
  (slice) => slice.deviceTransactionsSummary,
);
export const selectLmcMultipoints = createAppSelector(deviceSlice, (slice) => slice.lmcMultipoints);
export const selectLmcMultipointsBySiteUuid = createAppSelector(
  selectLmcMultipoints,
  selectLmcsBySiteUuid,
  (lmcMultipoints, lmcs) =>
    lmcs.flatMap((lmc) => {
      const lmcName = lmc.name || lmc.serialNumber;
      return lmcMultipoints
        .filter((mp) => mp.lmcDeviceUuid === lmc.uuid)
        .map((mp) => ({ ...mp, name: !mp.multipointId ? lmcName : mp.name }));
    }),
);
export const selectLmcMultipointsByLmcDeviceUuid = createAppSelector(
  selectLmcMultipoints,
  (state: RootState, lmcDeviceUuid: string | undefined) => lmcDeviceUuid,
  (lmcMultipoints, lmcDeviceUuid) =>
    lmcDeviceUuid ? lmcMultipoints.filter((d) => d.lmcDeviceUuid === lmcDeviceUuid) : [],
);
