import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { Site } from 'models/site';

import Button from '@components/atoms/Button';
import EditingSidebarBase from '@components/sidebars/EditingSidebarBase';
import { ReactComponent as ArrowLeftIcon } from '@images/icons/arrow_left.svg';

export const WorkspaceInfoSidebar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { site } = useOutletContext<{ site: Site }>();

  return (
    <EditingSidebarBase
      title={t('publicCharging', 'Public charging')}
      saveLabel={t('next', 'Next')}
      discardLabel={t('cancel', 'Cancel')}
      noActions
    >
      <div className="pb-6 font-poppins">
        {t('beforePublicCharging', 'Before public charging can be enabled, please provide:')}
        <br />
        <br />
        <p>• {t('yourCompanyDetails', 'Your company details')}</p>
        <p>• {t('aPayoutMethod', 'A payout method')}</p>
        <br />
        <p>{t('onceTheseAddedPublicSite', 'Once these are added, your site will be publicly available.')}</p>
      </div>
      <Button
        variant="primary"
        className="flex w-full items-center justify-center font-poppins text-base font-semibold"
        onClick={() => navigate(`/sites/workspace?siteUuid=${site?.uuid}`)}
      >
        {t('letsDoIt', 'Lets do it!')}
        <ArrowLeftIcon className="h-4 rotate-180 [&_path]:fill-white" />
      </Button>
    </EditingSidebarBase>
  );
};
