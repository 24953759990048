import { BaseQueryApi, FetchArgs, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import jsCookie from 'js-cookie';

import { getApiUrl } from '@config/api';
import { isSubdomainPartner } from '@routesConfig/routesUtil';
import { setCredentials } from '@services/auth/actions';

import { buildEndpoints } from './endpoints';

const baseQuery = fetchBaseQuery({
  baseUrl: getApiUrl(),
  prepareHeaders: (headers, { getState }: { getState: () => any }) => {
    const { token } = getState().auth;

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    } else {
      const tokenCookie = jsCookie.get('ad_token');

      if (tokenCookie) {
        headers.set('Authorization', `Bearer ${tokenCookie}`);
      }
    }

    if (isSubdomainPartner()) {
      const partnerCompanyUuid = jsCookie.get('activePartnerCompanyUuid');
      if (partnerCompanyUuid) {
        headers.set('Partner-Company-Uuid', partnerCompanyUuid);
      }
    }

    return headers;
  },
});

const baseQueryWithReauth = async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: Record<string, any>) => {
  const hasUnauthorizedError = (res: any) => res.error?.status === 401;

  let result = await baseQuery(args, api, extraOptions);

  if (hasUnauthorizedError(result)) {
    const oldRefreshToken = jsCookie.get('ad_refreshToken');

    if (oldRefreshToken) {
      const refreshResult = await baseQuery(
        {
          url: '/auth/refresh-token',
          method: 'POST',
          body: { refreshToken: oldRefreshToken },
        },
        api,
        extraOptions,
      );

      if (hasUnauthorizedError(refreshResult)) {
        // FIXME: can and should we do better?
        window.location.href = '/welcome';
        return { error: 'cannot refresh the token' };
      }

      const { token, refreshToken: newRefreshToken } = refreshResult.data as { token: string; refreshToken: string };

      api.dispatch(setCredentials({ token, refreshToken: newRefreshToken }));
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};

const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'CompanyContext',
    'CompanyDetails',
    'CompanyPartnership',
    'CompanyPayoutDetails',
    'CompanyUsers',
    'Groups',
    'OcppVariables',
    'PartnerContext',
    'PaymentMethods',
    'ReportSchedule',
    'Vehicle',
    'Site',
    'Subscription',
  ],
  endpoints: (builder) => buildEndpoints(builder),
});

export default apiSlice;
