import { Device } from 'models/device';
import { Inverter, InverterSummaryQuery, InvertersSummary } from 'models/inverter';
import { AddSiteDto, Site, SiteSettings, UpdateSiteDto, UpdateSiteSettingsDto } from 'models/site';

import apiSlice from '@services/api';

export const sitesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSites: builder.query<Site[], { companyUuid?: string }>({
      query: ({ companyUuid }) => ({
        url: '/sites',
        method: 'GET',
        params: { companyUuid },
      }),
      providesTags: ['CompanyContext'],
      transformResponse: (response: { sites: Site[] }) => response.sites,
    }),
    getAllSites: builder.query<Site[], void>({
      query: () => '/sites/all',
      transformResponse: (response: { sites: Site[] }) => response.sites,
      providesTags: ['PartnerContext'],
    }),
    addSite: builder.mutation<Site, AddSiteDto>({
      query: ({ name, address, publicFlag, addressJson, pricingModel, revenuePricingModel }) => ({
        url: '/sites',
        method: 'POST',
        body: {
          name,
          address,
          publicFlag,
          addressJson,
          pricingModel,
          revenuePricingModel,
        },
      }),
      transformResponse: (response: { site: Site }) => response.site,
    }),
    getSite: builder.query<Site, string>({
      query: (uuid) => ({
        url: `/sites/${uuid}`,
        method: 'GET',
      }),
      transformResponse: (response: { site: Site }) => response.site,
    }),
    updateSite: builder.mutation<Site, UpdateSiteDto>({
      query: ({ uuid, ...body }) => ({
        url: `/sites/${uuid}`,
        method: 'PUT',
        body,
      }),
      transformResponse: (response: { site: Site }) => response.site,
      invalidatesTags: ['Site'],
    }),
    updateSiteSettings: builder.mutation<SiteSettings, UpdateSiteSettingsDto>({
      query: ({ uuid, settings }) => ({
        url: `/sites/${uuid}/settings`,
        method: 'PUT',
        body: settings,
      }),
      transformResponse: (response: { settings: SiteSettings }) => response.settings,
    }),
    deleteSite: builder.mutation<{ siteUuid: string }, string>({
      query: (uuid) => ({
        url: `/sites/${uuid}`,
        method: 'DELETE',
      }),
    }),
    getSiteDevices: builder.query<{ devices: Device[] }, string>({
      query: (uuid) => ({
        url: `/sites/${uuid}/devices`,
        method: 'GET',
      }),
      providesTags: ['Site'],
    }),
    getSiteInverters: builder.query<Inverter[], string>({
      query: (uuid) => ({
        url: `/sites/${uuid}/inverters`,
        method: 'GET',
      }),
      transformResponse: (response: { data: Inverter[] }) => response.data,
    }),
    getSiteInvertersSummary: builder.query<InvertersSummary, InverterSummaryQuery>({
      query: ({ uuid, duration }) => ({
        url: `/sites/${uuid}/inverters/summary${duration ? `?duration=${duration}` : ''}`,
        method: 'GET',
      }),
    }),
    getCompanyPublicConnectorsCount: builder.query<number, void>({
      query: () => ({
        url: `/sites/public/connectors/count`,
        method: 'GET',
      }),
      transformResponse: (response: { count: number }) => response.count,
    }),
  }),
});

export const {
  useGetSitesQuery,
  useGetAllSitesQuery,
  useAddSiteMutation,
  useGetSiteQuery,
  useUpdateSiteMutation,
  useUpdateSiteSettingsMutation,
  useDeleteSiteMutation,
  useGetSiteDevicesQuery,
  useGetSiteInvertersQuery,
  useGetSiteInvertersSummaryQuery,
  useLazyGetCompanyPublicConnectorsCountQuery,
} = sitesApi;
