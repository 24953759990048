import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { SummaryCard } from '@components/cards/SummaryCard';
import TimePeriodSelectorHeader from '@components/filterComponents/TimePeriodSelectorHeader';
import useActiveCurrency from '@hooks/useActiveCurrency';
import { selectDeviceTransactionsSummary } from '@services/devices/selectors';
import { useAppSelector } from '@services/hooks';
import { DurationKey } from '@services/transactions/chartDataParser';

type ChargerSummaryProps = {
  isPrivateSite: boolean;
  selectedDuration?: DurationKey;
  setSelectedDuration: Dispatch<SetStateAction<DurationKey | undefined>>;
};

export const ChargerSummary = ({ isPrivateSite, selectedDuration, setSelectedDuration }: ChargerSummaryProps) => {
  const { t } = useTranslation();
  const currency = useActiveCurrency();
  const transactionsSummary = useAppSelector(selectDeviceTransactionsSummary);

  return (
    <div className="relative">
      <TimePeriodSelectorHeader
        selectedDuration={selectedDuration}
        setSelectedDuration={setSelectedDuration}
        labelPrefix={t('energySuppliedFor', 'Energy supplied for')}
        storageKey="chargerSummaryDuration"
        withoutAll
      />
      <div className="mb-4 flex flex-wrap items-stretch gap-4">
        {isPrivateSite ? (
          <SummaryCard
            label={t('cost', 'Cost')}
            unit={currency.symbol}
            value={transactionsSummary?.totalEnergyCost ?? 0}
            tooltip={t('vatNotIncluded', 'VAT not included')}
          />
        ) : (
          <>
            <SummaryCard
              label={t('revenue', 'Revenue')}
              unit={currency.symbol}
              value={transactionsSummary?.totalRevenue ?? 0}
              tooltip={t('vatNotIncluded', 'VAT not included')}
            />
            <SummaryCard
              label={t('profit', 'Profit')}
              unit={currency.symbol}
              value={transactionsSummary?.totalEnergyProfit ?? 0}
              tooltip={t('vatNotIncluded', 'VAT not included')}
            />
          </>
        )}
        <SummaryCard
          label={t('sessions', 'Sessions')}
          value={transactionsSummary?.totalTransactions ?? 0}
          valueDecimalPlaces={0}
        />
        <SummaryCard label={t('consumption', 'Consumption')} unit="kWh" value={transactionsSummary?.totalKwh ?? 0} />
      </div>
    </div>
  );
};
